import React, { useEffect, useRef, useState, useContext } from 'react';
import axios from 'axios';
import hostlink from '../Hostlink/hostlink';
import * as XLSX from 'xlsx';
import { PDFDownloadLink, PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { UserLoginDetails } from '../Hostlink/UserLoginDetails';

const Rpt_TableStatus = () => {
    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;
    const [RoomType, setRoomType] = useState([]);
    const [Room, setRoom] = useState([]);
    const [data, setData] = useState([]);

    const [frmdata, setfrmdata] = useState({
        rtype: "0",
        rcode: "0",
        status: "3"
    });

    const { rtype, rcode, status } = frmdata;

    const tableRef = useRef(null);

    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setfrmdata({ ...frmdata, [name]: sanitizedValue });
    };

    const getroombyid = (e) => {
        if (`${e}`==0){
            axios.get(`${hostlink}/${usershopid}/room`)
            .then((Response) => {setRoom(Response.data)
            }).catch((Response) => { "data Not Found" });

        }
        else{
            axios.get(`${hostlink}/room/getnamebyid/${e}`)
            .then((Response) => {setRoom(Response.data)
            }).catch((Response) => { "data Not Found" });

        }
    };

    const loaddata = async () => {
        
        await axios.get(`${hostlink}/${usershopid}/table`)
            .then((response) => {
                setData(response.data);
            })
            .catch((resp) => {
                console.log("Data Not Found...");
            });
    };

    const exportToExcel = () => {
        const ws = XLSX.utils.table_to_sheet(tableRef.current); // Convert table to worksheet
        const boldAndColorStyle = {
            font: { bold: true },
            fill: { fgColor: { rgb: "FFFF00" } } // Yellow color, you can change the color as needed
        };
        const headerRow = XLSX.utils.decode_range(ws['!ref']).e.c;
        for (let c = 0; c <= headerRow; c++) {
            const cellAddress = XLSX.utils.encode_cell({ r: 0, c });
            if (ws[cellAddress].v === 'Sr No') { // Check if the cell value is 'Sr No'
                ws[cellAddress].s = boldAndColorStyle;
                for (let r = 1; r <= XLSX.utils.decode_range(ws['!ref']).e.r; r++) {
                    const dataCellAddress = XLSX.utils.encode_cell({ r, c });
                    if (ws[dataCellAddress]) {
                        ws[dataCellAddress].s = boldAndColorStyle;
                    }
                }
                break;
            }
        }
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        XLSX.writeFile(wb, 'table_status.xlsx');
    };

    
    return (
        <div className='m-1'>
            <div className='container p-3 shadow rounded' style={{ transition: 'box-shadow 0.3s' }}>
                <div className='row'>
                    <div className='col-md-3 align-self-end'>
                        <h5 className='font-monospace text-primary'> <i class="fa-solid fa-table-cells-large" style={{ width: "20px" }}></i> <span id='pagemode' > Table Status</span> </h5>
                    </div>
                    {/* <div className='col-md-1 align-self-center'><label for="inputState" class="form-label">Room Type </label></div>
                    <div className='col-md-2'><select id="roomtype" onChange={(e) => { onChangevalue(e); getroombyid(e.target.value); }} value={rtype} name='rtype' class="form-select">
                        <option selected key={0} value={0}>All</option>
                        {RoomType.map((x) => { return (<option key={x.id} value={x.id}> {x.rtname} </option>) })}</select>
                    </div>
                    <div className='col-md-1 align-self-center'><label for="inputState" class="form-label">Room :</label></div>
                    <div className='col-md-2'><select id="roomtype" onChange={(e) => { onChangevalue(e) }} value={rcode} name='rcode' class="form-select">
                        <option selected key={0} value={0}>All</option>
                        {Room.map((x) => { return (<option key={x.id} value={x.id}> {x.rname} </option>) })}</select>
                    </div>
                    <div className='col-md-1 align-self-center'><label for="inputState" class="form-label">Status :</label></div>
                    <div className='col-md-2'><select id="roomtype" onChange={(e) => { onChangevalue(e) }} value={status} name='status' class="form-select">
                        <option selected key={0} value={3}>All</option>
                        <option selected key={0} value={1}>Booked</option>
                        <option selected key={0} value={0}>Available</option>
                    </select>
                    </div> */}
                    <div className='col-md-12 align-self-center text-end my-2'>
                        <button onClick={() => { loaddata(); }} className='btn btn-sm btn-outline-primary'> <i class="fa-solid fa-magnifying-glass-chart"></i> Show</button> &nbsp;
                        <button onClick={() => { exportToExcel(); }} className='btn btn-sm btn-outline-primary'><i class="fa-solid fa-table"></i> Excel</button> &nbsp;

                        <PDFDownloadLink document={<PDFDocument data={data} />} fileName="table_status.pdf">
                            {({ blob, url, loading, error }) =>
                                loading ? 'load' : 'data'
                            }
                            <button className='btn btn-sm btn-outline-primary'><i class="fa-regular fa-file-pdf"></i> PDF </button>
                        </PDFDownloadLink>

                    </div>
                </div>
            </div>

            <br />
            <div className='container' style={{ maxHeight: "500px", overflow: "auto" }}>
                <table className="table table-hover table-striped" ref={tableRef}>
                    <thead>
                        <tr>
                            <th scope="col">Sr No</th>
                            <th scope="col">Table Name</th>
                            <th scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody>

                        {data.map((res, x) => (
                            <tr key={x} className={res.status === 0 ? '' : 'table-info'}><th scope="row">{x + 1}</th>
                                <td>{res.tname}</td>
                                <td>{res.status === 0 ? 'Available' : 'Running'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
const PDFDocument = ({ data }) => (
    <Document>
        <Page>
            <View style={styles.container}>
                <Text style={styles.title}>Table Status</Text>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <Text style={styles.headerCell}>Sr No</Text>
                        <Text style={styles.headerCell}>Table Name</Text>
                        <Text style={styles.headerCell}>Status</Text>
                    </View>
                    {data.map((res, x) => (
                        <View key={x} style={styles.tableRow}>
                            <Text style={styles.cell}>{x + 1}</Text>
                            <Text style={styles.cell}>{res.tname}</Text>
                            <Text style={styles.cell}>{res.status === 0 ? 'Available' : 'Running'}</Text>
                        </View>
                    ))}
                </View>
            </View>
        </Page>
    </Document>
);

const styles = StyleSheet.create({
    container: {
        padding: 20,
    },
    title: {
        fontSize: 20,
        marginBottom: 20,
        textAlign: 'center',
    },
    table: {
        display: 'table',
        width: '100%',
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderColor: '#000',
    },
    headerCell: {
        fontSize: 14,
        fontWeight: 'bold',
        padding: 5,
        flex: 1,
        textAlign: 'center',
    },
    cell: {
        fontSize: 14,
        padding: 5,
        flex: 1,
        textAlign: 'center',
    },
});


export default Rpt_TableStatus;
