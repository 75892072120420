import React, { useEffect, useRef, useState, useContext } from 'react'
import hostlink from '../Hostlink/hostlink';

import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { UserLoginDetails } from '../Hostlink/UserLoginDetails';

const TransactionDetail = () => {
    const [AccCode, setAccCode] = useState([]);
    const roomCodeRef = useRef(null);
    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;
    const deptcode = appState?.[0]?.deptcode || 0;
    const usershopidref = appState?.[0]?.hotelshopid || 0;
    const userroomshopid = appState?.[0]?.roomshopid || 0;
    const queryConditionRoomServ = userroomshopid !== 0 ? userroomshopid : usershopid;
    const queryCondition = usershopidref !== 0 ? usershopidref : usershopid;
    const scrollRef = useRef(null);
    const [itemdetailGridRefresh, setitemdetailGridRefresh] = useState(true)
    const [editDeleteAPi, seteditDeleteAPi] = useState('');
    const [query, setQuery] = useState('');
    const [data, setData] = useState([]);
    const [srvdata, setsrvData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [listVisible, setListVisible] = useState(false);
    const inputRef = useRef(null);

    const fetchData = async () => {

        const response = await fetch(`${hostlink}/${usershopid}/accmascashbankhide`);
        const jsonData = await response.json();
        setData(jsonData);
    };

    const printdata = async (e) => {
        try {
            const response = await axios.get(`${hostlink}/getchehinroomservicePrint/${usershopid}/${e}`);
            const printWindow = window.open('', '_blank');
            if (response && response.data && response.data.length > 0) {
                const printContent = generatePrintContent(response.data);
                const data = `1,${hostlink}/getchehinroomservicePrint/${usershopid}/${e},${printContent}`;
                console.log(data)
                // Check if ReactNativeWebView is available
                if (window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage(data);
                    return false;
                }
                printWindow.document.write(printContent);
                printWindow.document.close();
            } else {
                console.error("Empty or invalid response received.");
                return;
            } printWindow.print();
        } catch (error) {
            console.error("Error fetching or printing data:", error);
        }
    };

    const generatePrintContent = (data) => {
        console.log(data)
        const tableInfo = data.length > 0 && data[0] !== null ? `<span style="text-align: end; width:50%;"><p1 style="text-align: end; width:50%;">Room Name : ${data[0][0]}</p1></span>` : '';
        return `<style>*{margin: 0;padding: 0;box-sizing: border-box;}div{width: 3in;}hr{width: 3in;}</style><div style="font-family: 'Bahnschrift Condensed'; width: 3in;"><p1 style="display: block; margin: 0 auto;text-align: center;"> ROOM SERVICE </p1>
        <img style="display: block; margin: 0 auto;" src="./logo${usershopid}.png" alt="" />
        <h1 style="text-align: center;">${appState?.[0]?.cname || '-'}</h1><hr style="borderTop: 1px solid black; width: 3in;" /><span style="display:flex;"><p1 style="text-align: start; width:50%;">Ord No : ${data.length > 0 ? data[0][13] : ''}</p1>${tableInfo}</p1></span><span style="display:flex; width: 3in;"><p1 style="text-align: start; width:50%;">Ord Date : ${data[0][7] ? new Date(data[0][7]).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"}</p1><p1 style="text-align: end; width:50%;">Ord Time : ${data[0][8] ? new Date(`2022-01-01T${data[0][8]}`).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }) : "-"}</p1></span><table style="border-Top: 1px solid black; width: 3in;"><tr style="border-bottom: 1px solid black;"><td style="width: 10%;border-bottom: 1px solid black;">SN</td><td style="width: 80%;border-bottom: 1px solid black;">Item Name</td><td style="width: 10%;border-bottom: 1px solid black; text-align: end;">Qty</td></tr>
          ${data.map((x, index) => `<tr style="width: 100%"><td style="width: 10%">${index + 1}</td><td style="width: 80%">${x[1]}</td><td style="width: 10%; text-align: end;">${x[2].toFixed(2)}</td></tr>`).join('')}</table><hr style="borderTop: 1px solid black; width: 3in;" /><span style="display:flex;"><p1 style="text-align: end; width:100%;">Total Qty : ${Number(data.reduce((total, resp) => total + parseFloat(resp[2]), 0)).toFixed(2)}</p1></span><hr style="borderTop: 1px solid black; width: 3in;" />      
        </div>`;
    };
    useEffect(() => {
        fetchData();
    }, [usershopid]);

    const cmdEdit = (paymentsrno) => {
        let x = document.getElementById("viewform")
        x.style.display = "None"
        let y = document.getElementById("newform")
        y.style.display = "block"
        axios.get(`${hostlink}/getchehinroomservicefindone/${usershopid}/${paymentsrno}`)
            .then((resp) => {
                setitemdetailGrid(resp.data)
                var element = document.getElementById("roomcode");
                element.value = resp.data[0].rcode;
                element.dispatchEvent(new Event('change'));
                const roomCode = resp.data[0].rcode;
                const event = new Event('change', { bubbles: true });
                roomCodeRef.current.value = roomCode;
                roomCodeRef.current.dispatchEvent(event);

            })
    }
    const cmdDelete = (paymentsrno) => {

        axios.delete(`${hostlink}/VoucherMasdeleteTr/${usershopid}/${paymentsrno}`)
            .then((resp) => {
                toast.success("Delete Sucessfull")
                setTimeout(() => {
                    setitemdetailGridRefresh(true)
                }, 1000);
            })
            .catch((resp) => {
                console.log("Something Else")
            })
    }

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        setQuery(inputValue);
        filterData(inputValue);
        setListVisible(true);
        setSelectedIndex(-1);
    };

    const filterData = (query) => {
        if (!query) {
            setFilteredData([]);
            return;
        }

        const filtered = data.filter(item => {
            return item.accname && item.accname.toLowerCase().includes(query.toLowerCase());
        }).slice(0, 5); // Limit to first 5 records after filtering

        setFilteredData(filtered);
    };
    const saveroomservice = async (isprint) => {
        document.getElementById("cmdsave").disabled = true

        if (itemdetailGrid.length === 0) {
            toast.error("Nothing to Save. Add Item...")
            document.getElementById("cmdsave").disabled = false
            return false
        }

        if (billdate == "") {
            toast.error("Please Select Date...");
            document.getElementById("cmdsave").disabled = false
            return false
        }

        if (paytypehead == 0) {
            toast.error("Please Select Account...");
            document.getElementById("cmdsave").disabled = false
            document.getElementById("paytypehead").focus();

            return false
        }


        const time = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });




        for (let i = 0; i < itemdetailGrid.length; i++) {
            itemdetailGrid[i].id = '';
            itemdetailGrid[i].refacccode = paytypehead;
            itemdetailGrid[i].vtype = paytype;
            itemdetailGrid[i].billdate = billdate;
            itemdetailGrid[i].entrtytime = time;
            itemdetailGrid[i].shopid = queryConditionRoomServ;
            itemdetailGrid[i].totalvamount = itemdetailGrid.reduce((total, resp) => total + parseFloat(resp.vamount), 0);
            itemdetailGrid[i].totalvamount = itemdetailGrid.reduce((total, resp) => total + parseFloat(resp.vamount), 0);
        }
        // console.log(itemdetailGrid)
        // return false

        await axios.post(`${hostlink}/VoucherMasPayment`, itemdetailGrid)
            .then((resp) => {
                toast.success("Save Sucess..");
                setInterval(() => {
                    window.location.assign("/TransactionDetail")
                }, 1000);
            })
            .catch((resp) => {
                console.log("Data Not Save..")
            })
    }
    const handleSelectItem = (item, index) => {
        setSelectedItem(item);
        setQuery(item.itname);
        setListVisible(false);
        setSelectedIndex(index);
    };
    const [itemdetail, setitemdetail] = useState({ acccode: 0, accname: "", remarks: "", vamount: "" });
    const { acccode, accname, remarks, vamount } = itemdetail;
    const [itemdetailGrid, setitemdetailGrid] = useState([]);
    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
    }, [itemdetailGrid]);

    useEffect(() => {
        axios.get(`${hostlink}/${usershopid}/accmascashbank`)
            .then((Response) => { setAccCode(Response.data) })
        axios.get(`${hostlink}/VoucherMasView/${usershopid}/${fromdate}/${todate}/0`)
            .then((Response) => {
                setsrvData(Response.data)
                setitemdetailGridRefresh(false)
            })
            .catch((Response) => {
                console.log("Data Not Fatched")
            })
    }, [itemdetailGridRefresh, usershopid]);
    const handleItemClick = (index) => {
        const queryCondition = filteredData[index].ispackage == 0
            ? filteredData[index].restrate
            : 0;

        setSelectedIndex(index);
        handleSelectItem(filteredData[index], index);
        setQuery(filteredData[index].accname);
        setitemdetail({
            ...itemdetail,
            acccode: filteredData[index].id,
            accname: filteredData[index].accname,
        });
        document.getElementById("vamount").focus();
    };
    const handleKeyDown = (event) => {
        if (document.getElementById("inputname1111").value == "") {
            return false
        }
        if (event.key === 'ArrowDown') {
            event.preventDefault();
            setSelectedIndex(prevIndex => (prevIndex < filteredData.length - 1 ? prevIndex + 1 : prevIndex));
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            setSelectedIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
        } else if (event.key === 'Enter' && selectedIndex !== -1) {
            handleSelectItem(filteredData[selectedIndex], selectedIndex);
            setQuery(filteredData[selectedIndex].accname);
            setitemdetail({
                ...itemdetail,
                acccode: filteredData[selectedIndex].id,
                accname: filteredData[selectedIndex].accname,
            });
            document.getElementById("vamount").focus();
        }
    };

    const handleFocus = (e) => {
        // setTimeout(() => {
        //     e.target.select(); // Select the input value when it receives focus
        // }, 0);
    };
    const handleKeyDownnext = (event) => {
        if (event.keyCode === 39) {
            event.preventDefault(); // Prevent cursor from moving to the end
            event.target.setSelectionRange(0, event.target.value.length); // Select the input value
        }
        if (event.keyCode === 13 || event.keyCode === 9) { // Check if the Enter key or Tab key is pressed
            event.preventDefault(); // Prevent default Tab behavior
            const interactiveElements = document.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
            const currentIndex = Array.from(interactiveElements).indexOf(event.target); // Get the index of the current interactive element
            if (currentIndex < interactiveElements.length - 1) {
                interactiveElements[currentIndex + 1].focus(); // Focus on the next interactive element
            } else if (event.keyCode === 9) {
                interactiveElements[0].focus();
            }
        }
    };

    const today = new Date();
    const onchange = (e) => {
        setvouchHeaddata({ ...vouchHeaddata, [e.target.name]: e.target.value })
    }

    const [frmdata, setfrmdata] = useState({
        fromdate: today.toISOString().split('T')[0],
        todate: today.toISOString().split('T')[0],
        status: 3
    });
    const { fromdate, todate, status } = frmdata;

    const [vouchHeaddata, setvouchHeaddata] = useState({ paytype: 3, paytypehead: 0, billdate: today.toISOString().split('T')[0] });
    const { paytype, billdate, paytypehead } = vouchHeaddata;



    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,<>?=]/g, '');
        setitemdetail({ ...itemdetail, [name]: sanitizedValue });
    }
    const onChangevalueloaddata = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setfrmdata({ ...frmdata, [name]: sanitizedValue });
        setitemdetailGridRefresh(true)
    }
    const [isVisible, setIsVisible] = useState(false);

    const [Room, setRoom] = useState([])
    const [items, setItems] = useState([]);
    const handleDoubleClick = (itemId) => {
        const selectedItem = items.find(item => item.id === itemId);
        if (selectedItem) {
            alert(`Double-clicked on: ${selectedItem.itname}`);
        }
    };
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    // Show button when user scrolls down 400px
    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
        if (usershopidref == 0) {
            // const response = await fetch(`${hostlink}/${usershopid}/item`);
            // const jsonData = await response.json();
            // setData(jsonData);
            axios.get(`${hostlink}/${usershopid}/item`)
                .then((Response) => {
                    setItems(Response.data)
                })
                .catch((Response) => {
                    console.log("Data Not Fatched")
                })
        } else {
            axios.get(`${hostlink}/${queryCondition}/${deptcode}/storeitem`)
                .then((Response) => {
                    setItems(Response.data)
                })
                .catch((Response) => {
                    console.log("Data Not Fatched")
                })
        }


        const toggleVisibility = () => {
            if (window.pageYOffset > 100) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, [usershopid]);

    const handleDeleteItem = async (id) => {
        const updatedItDetails = itemdetailGrid.filter(item => item.acccode !== id);
        toast.success("deleted");
        setitemdetailGrid(updatedItDetails);
        document.getElementById("inputname1111").focus();
    };

    const handleAddItem = () => {

        if (acccode == 0) {
            toast.error("Please Select Party..");
            document.getElementById("inputname1111").focus();
            return false
        }
        if (vamount == 0) {
            toast.error("Please Enter Amount..");
            document.getElementById("vamount").focus();
            return false
        }

        const existingItem = itemdetailGrid.find(item => item.acccode === acccode);
        if (existingItem) {
            toast.error("Already exists..");
            setQuery('')
            setitemdetail({
                ...itemdetail,
                acccode: 0,
                accname: "",
                remarks: "",
                vamount: ""
            });
            document.getElementById("inputname1111").focus();
        } else {
            setitemdetailGrid([...itemdetailGrid, {
                acccode: acccode,
                accname: accname,
                vamount: vamount,
                remarks: remarks
            }])
            toast.success("Item added..");
            setQuery('')
            setitemdetail({
                ...itemdetail,
                acccode: 0,
                accname: "",
                vamount: "",
                remarks: ""
            });
            document.getElementById("inputname1111").focus();
        }
    };
    const cmdNew = () => {
        seteditDeleteAPi('')
        let x = document.getElementById("viewform")
        x.style.display = "None"
        let y = document.getElementById("newform")
        y.style.display = "block"
    }


    const searchData = (e) => {
        const searchValue = document.getElementById("searchroom").value.trim();
        console.log(searchValue)
        if (searchValue === "") {
            axios.get(`${hostlink}/VoucherMasView/${usershopid}/${fromdate}/${todate}/0`)
                .then((Response) => {
                    setsrvData(Response.data)
                    setitemdetailGridRefresh(false)
                })
                .catch(() => { })
        } else {
            axios.get(`${hostlink}/VoucherMasView/${usershopid}/${fromdate}/${todate}/${searchValue}`)
                .then((Response) => {
                    setsrvData(Response.data)
                    setitemdetailGridRefresh(false)
                })
                .catch(() => { })
        }
    }
    return (


        <div>
            <div id='viewform'>
                <div className='container p-2'>
                    <div className='row p-1'>
                        <div className='col-md-2 p-1'>
                            <h5 className='font-monospace'><i class="fa-solid fa-money-bill-transfer"></i> Transaction</h5>
                        </div>
                        <div className='col-md-2 rounded' style={{ display: "flex", alignItems: "center", background: "rgb(245,245,246)", height: "40px" }}>
                            <i class="fa-solid fa-magnifying-glass " ></i> &nbsp;&nbsp; <input type="text" class="" name="searchroom" onChange={(e) => { searchData(e) }} id="searchroom" placeholder="Search" style={{ background: "rgb(245,245,246)", outline: "none", border: "0", width: "100%" }} /> <br />
                        </div>

                        <div className='col-md-6 align-self-end' style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                            <span className="f-size-on-mobile align-self-end" style={{ verticalAlign: 'middle', height: '100%', }}>
                                <label htmlFor="todate" className="form-label text-center">From : &nbsp;</label>
                            </span>
                            <span> <input type="date" id="fromdate" name="fromdate" onChange={(e) => { onChangevalueloaddata(e) }} value={fromdate} className="form-control" /></span>


                            <span className="f-size-on-mobile align-self-end">
                                <label htmlFor="todate" className="form-label text-center">&nbsp; To : &nbsp;</label>
                            </span>
                            <span>
                                <input type="date" id="todate" name="todate" onChange={(e) => { onChangevalueloaddata(e) }} value={todate} className="form-control" />
                            </span>
                        </div>


                        <div className='col-md-2 text-end'>
                            {(userType === 0 || appState[1]?.[0]?.transaction?.charAt(1) === '1') ? (<button id='cmdnew' onClick={cmdNew} className='btn btn-outline-primary'> <i class="fa-solid fa-plus"></i> &nbsp;Transaction </button>) : null}
                        </div>
                        <div style={{ maxHeight: "80vh", overflow: "auto" }} className='col-12 '>
                            <table className="table table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col" className="f-size-on-mobile"><span className='hide-on-mobile'>Date</span> <span className='show-on-mobile'>Details</span></th>
                                        <th scope="col" className="hide-on-mobile">V No</th>
                                        <th scope="col" className="hide-on-mobile">Type</th>
                                        <th scope="col" className="hide-on-mobile">Time</th>
                                        <th scope="col" className="hide-on-mobile">Ledger Name</th>
                                        <th scope="col" className="hide-on-mobile">Dr</th>
                                        <th scope="col" className="hide-on-mobile">Cr</th>
                                        <th scope="col" className="f-size-on-mobile text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {srvdata.map((res, x) => (
                                        <tr style={{ verticalAlign: 'middle', height: '100%' }}>
                                            <td className="f-size-on-mobile" style={{ wordWrap: "break-word" }}> <span className='show-on-mobile'><b>Date :</b> </span> {res[0].billdate ? new Date(res[0].billdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"} <br />
                                                <div className="detail-on-mobile text-start align-self-start" style={{ fontSize: "12px", color: "blue", alignItems: "start", wordWrap: "break-word" }}>
                                                    {/* {res[6] !== '' && (<>Time : {res[6]} <br /></>)}
                                                    {res[2] !== '' && (<>Item : {res[2]} <br /></>)}
                                                    {res[10] !== 0 && (<>Order Amount : {res[10]} <br /></>)} <span style={{ color: "blue" }}> </span>
                                                    <span className='text-bg-danger'>{res[0] !== '' && (<>Room Name : {res[0]} <br /></>)}</span> */}
                                                </div>
                                            </td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res[0].paymentsrno}</td>

                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res[0].vtypename}</td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res[0].entrtytime}</td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res[1]}</td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>
                                                {res[0].vtype === 4 && res[0].refacccode == res[0].acccode
                                                    ? (res[0].vamt > 0 ? 0 : Math.abs(res[0].vamt))  // Logic for vtype 4
                                                    : res[0].vtype === 3 && res[0].refacccode !== res[0].acccode
                                                        ? (res[0].vamt < 0 ? Math.abs(res[0].vamt) : res[0].vamt)  // Logic for vtype 3 (opposite of vtype 4)
                                                        : 0  // Default for other cases
                                                }
                                            </td>

                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>
                                                {res[0].vtype === 4 && res[0].refacccode !== res[0].acccode
                                                    ? (res[0].vamt > 0 ? 0 : Math.abs(res[0].vamt))  // Logic for vtype 4
                                                    : res[0].vtype === 3 && res[0].refacccode == res[0].acccode
                                                        ? (res[0].vamt < 0 ? Math.abs(res[0].vamt) : res[0].vamt)  // Logic for vtype 3 (opposite of vtype 4)
                                                        : 0  // Default for other cases
                                                }
                                            </td>


                                            <td className='text-center' style={{ verticalAlign: 'middle', height: '100%' }}>
                                                {/* <Link to={"/TransactionDetail"} onClick={(e) => { cmdEdit(res[0].paymentsrno) }} className='btn btn-sm btn-outline-dark'><i class="fa-solid fa-pen-to-square"></i><span className="hide-on-mobile" > Edit</span></Link> &nbsp; <span className="detail-on-mobile"><br /> <br /></span> */}
                                                {/* <Link className='btn btn-sm btn-outline-primary' onClick={(e) => { printdata(res[11]) }}><i class="fa-solid fa-print"></i> <span className="hide-on-mobile">Print</span></Link> &nbsp; */}
                                                {(userType === 0 || appState[1]?.[0]?.transaction?.charAt(3) === '1') ? (<Link className='btn btn-sm btn-danger' onClick={(e) => { cmdDelete(res[0].paymentsrno) }} > <i class="fa-solid fa-trash"></i> <span className="hide-on-mobile"> Delete</span></Link>) : null}&nbsp;
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {/* New Service */}
            <div id='newform' style={{ display: "none" }}>
                <div className='container-fluid py-1 '>
                    <div className='row py-1 shadow rounded' style={{ backgroundImage: 'linear-gradient(to right, #5d757c, #5d757c, #5d757c)', color: 'white' }}>
                        <div className="col-md-3 align-self-center"><h4> &nbsp;<i class="fa-solid fa-money-bill-transfer"></i> Transaction</h4></div>
                        <div className="col-5 col-md-3 text-end align-self-center"><label for="inputState" class="form-label"> Transaction Type </label></div>
                        <div class="col-7 col-md-3 align-self-center">
                            <select id="paytype" onChange={(e) => { onchange(e) }} name='paytype' value={paytype} class="form-select"><option selected key={4} value={4}>Payment</option><option selected key={3} value={3}>Receipt</option></select>
                        </div>
                    </div>
                    <p></p>
                    <div className="row">
                        <div className="col-md-12">


                            <div className="row align-self-center text-center">

                                <div className="col-12 col-md-2"></div>
                                <div className="col-6 col-md-2">
                                    Voucher Date
                                </div>
                                <div className="col-6 col-md-2">
                                    <input onChange={(e) => { onchange(e) }} class="form-control" name='billdate' value={billdate} type="date" />
                                </div>
                                <div className="col-6 col-md-1">
                                    Account
                                </div>
                                <div className="col-6 col-md-2">
                                    <select id="paytypehead" name='paytypehead' value={paytypehead} onChange={(e) => { onchange(e) }} class="form-select"> <option selected key={0} value={0}>Not Selected </option>{AccCode.map((x) => { return (<option key={x.id} value={x.id}> {x.accname} </option>) })}</select>
                                </div>

                            </div>
                            <p></p>
                            <div className="row py-1">
                                <div className="col-md-2"></div>
                                <div className="col-md-3">
                                    <label className='font-monospace' class="form-label">Party Name</label>
                                    <input type="text" placeholder="Search for Party..." class="form-control" value={query} id="inputname1111" onFocus={handleFocus} onChange={handleInputChange} onKeyDown={handleKeyDown} ref={inputRef} /> <br />
                                    {listVisible && filteredData.length > 0 && (
                                        <ul style={{}}>
                                            {filteredData.map((item, index) => (
                                                <li
                                                    key={item.id}
                                                    onClick={() => handleSelectItem(item, index)}
                                                    onMouseDown={() => handleItemClick(index)}

                                                    className={index === selectedIndex ? 'selected' : ''}
                                                >
                                                    {item.accname}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>

                                <div className="col-4 col-md-1">
                                    <label className='font-monospace' class="form-label">Amount</label>
                                    <input type="text" value={vamount} name='vamount' onKeyDown={handleKeyDownnext} onFocus={handleFocus} onChange={(e) => { onChangevalue(e) }} class="form-control" id="vamount" /> <br />
                                </div>
                                <div className="col-8 col-md-3">
                                    <label className='font-monospace' class="form-label">Remarks</label>
                                    <input type="text" value={remarks} name='remarks' onKeyDown={handleKeyDownnext} onFocus={handleFocus} onChange={(e) => { onChangevalue(e) }} class="form-control" id="remarks" /> <br />
                                </div>
                                <div id='addroomNew' className="col-md-1 text-center">
                                    <label className='font-monospace ' style={{ color: "white" }} class="form-label">-</label> <br />
                                    <button style={{ backgroundImage: 'linear-gradient(to right, #ff9999, #ff9999, #ff9999)', color: 'black', fontSize: "12px", width: "90%", textAlign: "center", justifyContent: "center" }} type="submit" onClick={() => { handleAddItem() }} class="btn btn-info"><i class="fa-solid fa-circle-plus"></i> Add</button>
                                </div>

                            </div>

                            <div className='show-on-mobile row   container' style={{ color: 'black' }}>
                                {itemdetailGrid.map((resp, index) => (
                                    <div key={index} className='row text-center shadow rounded align-self-center p-1 m-0 bg-light border border-1'>
                                        <h6 style={{ "color": "blue", borderBottom: "1px solid black" }}>Party : {resp.accname}</h6>
                                        <div className='col-9 text-start' style={{ fontSize: "15px", color: "black" }}>
                                            <table style={{ "width": "100%" }}>
                                                <tr><td>Amount </td><td>: {resp.vamount}</td></tr>
                                                <tr><td>Remarks</td><td>: {resp.remarks}</td></tr>
                                            </table>
                                        </div>
                                        <div className='col-3 align-self-center'>
                                            <button type="submit" onClick={(e) => { handleDeleteItem(resp.acccode) }} className="btn btn-outline-danger"> <i className="fa-solid fa-trash"></i></button>
                                        </div>

                                    </div>
                                ))}
                            </div>

                            <div className='hide-on-mobile text-left align-self-center row bg-info p-1 m-0 shadow rounded border border-1' style={{ backgroundImage: 'linear-gradient(to right, #4DD6F4, #ff9999, #4DD6F4)', color: 'black', padding: '20px' }}>
                                <div className='row text-left align-self-center p-1 m-0 '>
                                    <div className='col-md-5'>Party Name</div>
                                    <div className='col-md-2 text-end'>Amount</div>
                                    <div className='col-md-4 '>Remarks</div>
                                    <div className='col-md-1 text-end'>Action</div>
                                </div>
                            </div>
                            <div className='hide-on-mobile' style={{ maxHeight: "365px", overflow: "auto" }} ref={scrollRef}>
                                {itemdetailGrid.map((resp, index) => (
                                    <div key={index} className='row text-left p-1 m-0 bg-light border border-1'>
                                        <div className='col-md-5 align-self-center'>{resp.accname}</div>
                                        <div className='col-md-2 align-self-center text-end'>{resp.vamount}</div>
                                        <div className='col-md-4 align-self-center '>{resp.remarks}</div>
                                        <div className='col-md-1 align-self-center text-end'>
                                            <button type="submit" onClick={(e) => { handleDeleteItem(resp.acccode) }} className="btn btn-outline-danger"> <i className="fa-solid fa-trash"></i></button>
                                        </div>
                                    </div>
                                ))}
                                <div className=' bg-dark text-bg-dark row text-left p-1 m-0 bg-light border border-1'>

                                    <div className='col-md-5 align-self-center text-end'><b>Total :</b></div>
                                    <div className='col-md-2 align-self-center text-end'><b>{Number(itemdetailGrid.reduce((total, resp) => total + parseFloat(resp.vamount), 0)).toFixed(2)}</b></div>
                                    <div className='col-md-5 align-self-center text-end'></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 ">
                        </div>
                    </div>
                </div>
                <br /><br /><br /><br />
                <div className="parent-container">
                    {/* Fixed element at the bottom */}
                    <div className="fixed-bottom" style={{ position: 'fixed', bottom: 0, width: '100%', backgroundColor: '#fff', borderTop: '1px solid black' }}>

                        <div className="container-fluid" >

                            <div className="row">
                                <div className="col-md-9 f-size-on-mobile text-end p-1" style={{ borderRight: "1px solid black" }}>
                                    <p className='hide-on-mobile m-2'></p>
                                    <button id='cmdsave' onClick={() => { saveroomservice(1) }} class="btn btn-primary">Save & Print</button>  &nbsp;
                                    <button id='cmdsave' onClick={() => { saveroomservice(0) }} class="btn btn-primary">Save</button>  &nbsp;
                                    <a href='/TransactionDetail' class="btn btn-outline-primary">Cancel</a>
                                </div>
                                {/* Summary */}
                                <div className="col-md-3 f-size-on-mobile ">
                                    <div className='rounded'>
                                        <div className="row p-1 m-1 f-size-on-mobile">
                                            <div className="col-6 text-end size-on-mobile" style={{ fontFamily: 'arial', fontSize: '18px', fontWeight: 'bold', backgroundColor: "black", color: "white" }}> Total :</div> <div style={{ fontFamily: 'arial', backgroundColor: "black", color: "white", fontSize: '16px', fontWeight: 'bold' }} className="col-6 text-end"> {Number(itemdetailGrid.reduce((total, resp) => total + parseFloat(resp.vamount), 0)).toFixed(2)} </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>


            </div>
            <ToastContainer autoClose={1000}></ToastContainer>
        </div>
    )
}

export default TransactionDetail