import React, { createContext, useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';
import softwaresecretKey from './softwaresecretKey';

export const UserLoginDetails = createContext();

export const AppProvider = ({ children }) => {
    const softwareSecretKey = softwaresecretKey;
    const [appState, setAppState] = useState({}); // Initialize as an empty object
    const [userLoginDetails, setUserLoginDetails] = useState(null);
    const [userType, setUserType] = useState(0); // Initialize as 0 (admin)

    // Load data from localStorage and decrypt it
    useEffect(() => {
        const storedData = localStorage.getItem('MYPCKARTUSER');
        if (storedData) {
            try {
                const bytes = CryptoJS.AES.decrypt(storedData, softwareSecretKey);
                const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                setUserLoginDetails(decryptedData);
                setAppState(decryptedData); // Set entire decrypted object as appState
                if (decryptedData.length === 1) {
                    setUserType(0); // Admin
                } else if (decryptedData.length > 1) {
                    setUserType(1); // User
                }
            } catch (error) {
                console.error("Error decrypting data:", error);
            }
        }
    }, []);

    // Function to dynamically update the state
    const updateAppState = (key, value) => {
        setAppState(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    return (

        <UserLoginDetails.Provider value={{ appState, userLoginDetails, updateAppState, userType }}>
            {children}
        </UserLoginDetails.Provider>
    );
};
