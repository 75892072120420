// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import App from './App';
// import "./global.css"; // Import your global CSS file

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <App />
// );
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AppProvider } from './Hostlink/UserLoginDetails';  // Import the AppProvider from the context file
import "./global.css"; // Import your global CSS file

const root = ReactDOM.createRoot(document.getElementById('root'));

// Wrap your app with the AppProvider (not the UserLoginDetails context directly)
root.render(
  <AppProvider> 
    <App />
  </AppProvider>
);
