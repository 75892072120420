import React, { useEffect, useRef, useState, useContext } from 'react'
import hostlink from '../Hostlink/hostlink';

import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import numberToWords from 'number-to-words';
import { UserLoginDetails } from '../Hostlink/UserLoginDetails';
const BanquetBook = () => {
    const roomCodeRef = useRef(null);
    const [FoodPackage, setFoodPackage] = useState([])
    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;
    const usershopidref = appState?.[0]?.hotelshopid || 0;
        
    const scrollRef = useRef(null);
    

    const queryCondition = usershopidref == 0
        ? `${usershopid}`
        : `${usershopidref}`;

    const [itemdetailGridRefresh, setitemdetailGridRefresh] = useState(true)
    const [editDeleteAPi, seteditDeleteAPi] = useState('');
    const [query, setQuery] = useState('');
    const [data, setData] = useState([]);
    const [srvdata, setsrvData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [listVisible, setListVisible] = useState(false);
    const inputRef = useRef(null);

    const fetchData = async () => {
        const response = await fetch(`${hostlink}/${usershopid}/item`);
        const jsonData = await response.json();
        setData(jsonData);
    };

    const printdata = async (e) => {
        try {
            console.log(`${hostlink}/bhbook/${e}`);
            const response = await axios.get(`${hostlink}/bhbook/${e}`);
            if (response && response.data && response.data.length > 0) {
                const printContent = generatePrintContent(response.data);
                const data = `1,${hostlink}/bhbook/${e},${printContent}`;
                console.log(data)
                // Check if ReactNativeWebView is available
                if (window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage(data);
                    return false;
                }
                // Create a new print window (iframe instead of new window)
                const printWindow = document.createElement('iframe');
                printWindow.style.position = 'absolute';
                printWindow.style.width = '0';
                printWindow.style.height = '0';
                printWindow.style.border = 'none';
                document.body.appendChild(printWindow);
                const doc = printWindow.contentWindow.document;
                doc.open();
                doc.write(printContent);
                doc.close();

                // Set CSS for printing
                const style = doc.createElement('style');
                style.innerHTML = `
                    @media print {
                        body, html {
                            width: 210mm;
                            margin: 2mm; /* Remove margins */
                            padding: 0; /* Remove padding */
                        }
                        * {
                            box-sizing: border-box; /* Include padding and border in element's width and height */
                        }
                        @page {
                            margin: 0; /* Remove margin */
                            size: 210mm 297mm; /* Set size to 80mm width */
                        }
                    }
                `;
                doc.head.appendChild(style);

                // Ensure the content is fully loaded before printing
                printWindow.onload = () => {
                    const contentHeight = doc.body.scrollHeight;
                    console.log("Content height:", contentHeight);
                    printWindow.contentWindow.print();
                    if (contentHeight > 210) {
                        doc.body.innerHTML += '\x1D\x56\x00'; // Manual cut command
                    }
                };
            } else {
                console.error("Empty or invalid response received.");
            }
        } catch (error) {
            console.error("Error fetching or printing data:", error);
        }
    };
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const generatePrintContent = (data) => {

        const companyGSTNO = appState?.[0]?.gstno || "-";
        const customergstno = "";

        // Ensure blgstamt is a valid number
        const blgstamt = 0;

        let print_cgst = 0;
        let print_sgst = 0;
        let print_igst = 0;

        // Compare the first two digits of both GST numbers
        if (companyGSTNO.substring(0, 2) === customergstno.substring(0, 2)) {
            print_igst = blgstamt;
        } else {
            print_cgst = (blgstamt / 2).toFixed(2);
            print_sgst = (blgstamt / 2).toFixed(2);
            print_igst = 0;
        }

        // Convert to number format for further usage
        print_cgst = Number(print_cgst);
        print_sgst = Number(print_sgst);
        print_igst = Number(print_igst);

        const billprintlineTC1 = appState?.[0]?.billprintlineTC1 || "-"; const Html_billprintlineTC1 = billprintlineTC1 !== null ? `<b> <p style="text-align: left; font-size: 12px; width:210mm;">${billprintlineTC1}</p> </b>` : '';
        const billprintlineTC2 = appState?.[0]?.billprintlineTC2 || "-"; const Html_billprintlineTC2 = billprintlineTC2 !== null ? `<p style="text-align: left; font-size: 10px; width:210mm;">${billprintlineTC2}</p> ` : '';
        const billprintlineTC3 = appState?.[0]?.billprintlineTC3 || "-"; const Html_billprintlineTC3 = billprintlineTC3 !== null ? `<p style="text-align: left; font-size: 10px; width:210mm;">${billprintlineTC3}</p> ` : '';
        const billprintlineTC4 = appState?.[0]?.billprintlineTC4 || "-"; const Html_billprintlineTC4 = billprintlineTC4 !== null ? `<p style="text-align: left; font-size: 10px; width:210mm;">${billprintlineTC4}</p> ` : '';
        const billprintlineTC5 = appState?.[0]?.billprintlineTC5 || "-"; const Html_billprintlineTC5 = billprintlineTC5 !== null ? `<p style="text-align: left; font-size: 10px; width:210mm;">${billprintlineTC5}</p> ` : '';


        const billprintline1 =   appState?.[0]?.billprintline1 || "-"; const billprintline1Html1 = billprintline1 !== null ? `<p style="text-align: center; width:210mm;">${billprintline1}</p>` : '';
        const billprintline2 =   appState?.[0]?.billprintline2 || "-"; const billprintline1Html2 = billprintline2 !== null ? `<p style="text-align: center; width:210mm;">${billprintline2}</p>` : '';
        const billprintline3 =   appState?.[0]?.billprintline3 || "-"; const billprintline1Html3 = billprintline3 !== null ? `<p style="text-align: center; width:210mm;">${billprintline3}</p>` : '';
        const billprintline4 =   appState?.[0]?.billprintline4 || "-"; const billprintline1Html4 = billprintline4 !== null ? `<p style="text-align: center; width:210mm;">${billprintline4}</p>` : '';
        const billprintline5 =   appState?.[0]?.billprintline5 || "-"; const billprintline1Html5 = billprintline5 !== null ? `<p style="text-align: center; width:210mm;">${billprintline5}</p>` : '';


        const custdlvdetailHtml = data.length > 0
            ? `` : '';

        // Resulting HTML
        const resultHtml = custdlvdetailHtml !== '' ? `<span style="font-size: 14px;">${custdlvdetailHtml}</span>` : '';

        const Totalbamt = data.reduce((total, resp) => {
            // Ensure resp.rent is a valid number
            const rentValue = parseFloat((resp.totordamt));
            return total + (isNaN(rentValue) ? 0 : rentValue);
        }, 0).toFixed(2);



        const Billamtinword = `<p style="width:205mm; border-bottom: 1px solid black;"><b>In Word Payable Amount :</b> ${capitalizeFirstLetter(numberToWords.toWords(Number(Number(data[0].totordamt-data[0].advanceamt).toFixed(2)).toFixed(2)).replace(/,/g, ''))}</p>`;


        return `<style>*{margin: 0;padding: 0;box-sizing: border-box;}div{width: 190mm;}hr{width: 210mm;}</style><div style="font-family: 'Bahnschrift Condensed'; width: 210mm;">
        <h1 style="text-align: center;">${appState?.[0]?.cname || '-'}</h1>
        ${billprintline1Html1}        ${billprintline1Html2}        ${billprintline1Html3}        ${billprintline1Html4}        ${billprintline1Html5}
        <h1 style="text-align: center;">BANQUET RESERVATION</h1>
         <table style="width: 205mm;border-Top: 1px solid black;">
                <tr style="border-bottom: 1px solid black; width: 205mm;"> 
                    <td style="width: 24%;border-bottom: 1px solid black;"><h3>Banquet Hall</h3> </td>
                    <td style="width: 1%;border-bottom: 1px solid black;"><h3>:</h3> </td> 
                    <td style="width: 35%;border-bottom: 1px solid black;"><h3>${data[0].bhname}</h3></td>

                    <td style="width: 15%;border-bottom: 1px solid black;">No of Guest </td>
                    <td style="width: 1%;border-bottom: 1px solid black;">:</td> 
                    <td style="width: 24%;border-bottom: 1px solid black;">${data[0].noofguest}</td>
                </tr">  

                 <tr style="border-bottom: 1px solid black; width: 205mm;"> 
                    <td style="width: 24%;border-bottom: 1px solid black;">Reservation Date </td>
                    <td style="width: 1%;border-bottom: 1px solid black;">:</td> 
                    <td style="width: 35%;border-bottom: 1px solid black;">${data[0].resdate}</td>

                    <td style="width: 15%;border-bottom: 1px solid black;">Check-in Date </td>
                    <td style="width: 1%;border-bottom: 1px solid black;">:</td> 
                    <td style="width: 24%;border-bottom: 1px solid black;">${data[0].checkindate}</td>
                </tr">  
            </table>
            </br>
            <b>Guest Details</b>
            <table style="width: 205mm;border-Top: 1px solid black;">
            
                <tr style="border-bottom: 1px solid black; width: 205mm;"> 
                    <td style="width: 24%;border-bottom: 1px solid black;">Guest Name </td>
                    <td style="width: 1%;border-bottom: 1px solid black;">:</td> 
                    <td style="width: 35%;border-bottom: 1px solid black;">${data[0].guestname}</td>
                    <td style="width: 15%;border-bottom: 1px solid black;">Mobile No </td>
                    <td style="width: 1%;border-bottom: 1px solid black;">:</td> 
                    <td style="width: 24%;border-bottom: 1px solid black;">${data[0].guestmob}</td>
                </tr">  

                </table>
                <table style="width: 205mm;">
                <tr style="border-bottom: 1px solid black; width: 205mm;"> 
                    <td style="width: 24%;">Address</td>
                    <td style="width: 1%; ">:</td> 
                    <td style="width: 75%;">${data[0].guestadd}</td>
                </tr">  
                

                <tr style="border-bottom: 1px solid black; width: 205mm;"> 
                    <td style="width: 24%;border-bottom: 1px solid black;"></td>
                    <td style="width: 1%;border-bottom: 1px solid black;"></td> 
                    <td style="width: 75%;border-bottom: 1px solid black;">${data[0].guestadd2}</td>
                </tr">  
                </table>
                <table style="width: 205mm;">
                <tr style="border-bottom: 1px solid black; width: 205mm;"> 
                    <td style="width: 24%;border-bottom: 1px solid black;">City</td>
                    <td style="width: 1%;border-bottom: 1px solid black;">:</td> 
                    <td style="width: 35%;border-bottom: 1px solid black;">${data[0].guestcity}</td>
                    <td style="width: 15%;border-bottom: 1px solid black;">Pin Code </td>
                    <td style="width: 1%;border-bottom: 1px solid black;">:</td> 
                    <td style="width: 24%;border-bottom: 1px solid black;">${data[0].guestpincode}</td>
                </tr">  

                <tr style="border-bottom: 1px solid black; width: 205mm;"> 
                    <td style="width: 24%;border-bottom: 1px solid black;">Email</td>
                    <td style="width: 1%;border-bottom: 1px solid black;">:</td> 
                    <td style="width: 35%;border-bottom: 1px solid black;">${data[0].guestemail}</td>
                    <td style="width: 15%;border-bottom: 1px solid black;">Refrence </td>
                    <td style="width: 1%;border-bottom: 1px solid black;">:</td> 
                    <td style="width: 24%;border-bottom: 1px solid black;">${data[0].refrence}</td>
                </tr">  
                </table>
                <table style="width: 205mm;">
                <tr style="border-bottom: 1px solid black; width: 205mm;"> 
                    <td style="width: 24%;border-bottom: 1px solid black;">Remarks</td>
                    <td style="width: 1%;border-bottom: 1px solid black;">:</td> 
                    <td style="width: 75%;border-bottom: 1px solid black;">${data[0].remarks}</td>
                </tr">  
                </table>
        </br>
            <table style="width: 205mm;">
                <tr style="width: 205mm;"> 
                    <td style="width: 83%; text-align: end;">Hall Rent </td>
                    <td style="width: 2%; text-align: end;">:</td> 
                    <td style="width: 15%; text-align: end;">${Number(data[0].hallrent*data[0].noofguest).toFixed(2)}</td>
                </tr">  
                <tr style="width: 205mm;"> 
                    <td style="width: 83%; text-align: end;">Food Package - <b>(${data[0].foodpackname})</b></td>
                    <td style="width: 2%; text-align: end;">:</td> 
                    <td style="width: 15%; text-align: end;">${Number(data[0].foodamount).toFixed(2)}</td>
                </tr">
                <tr style="width: 205mm;"> 
                    <td style="width: 86%; text-align: end;">No of Room - <b>${data[0].noofroom}</b>, Rent - <b> ${data[0].roomrent}</b> </td>
                    <td style="width: 2%; text-align: end;">:</td> 
                    <td style="width: 15%; text-align: end;">${Number(data[0].noofroom*data[0].roomrent).toFixed(2)}</td>
                </tr">  


                <tr style="width: 205mm;"> 
                    <td style="width: 86%; text-align: end;">Flat Discount</td>
                    <td style="width: 2%; text-align: end;">:</td> 
                    <td style="width: 15%; text-align: end;">${Number(data[0].flatdiscount).toFixed(2)}</td>
                </tr">  

            </table>
          
          <table style="border-Top: 1px solid black; width: 205mm;"></table>
          <table style="width: 205mm;">
           <tr style="border-bottom: 1px solid black; width: 205mm;">
                <td style="width: 60%;border-bottom: 1px solid black;">
                </td>
                    <td style="width: 40%;border-bottom: 1px solid black;">
                            <table style="">
                            <tr style="width: 100%; font-size: 22px;"><td style="width: 69%;"><b>Total Amount</b></td><td style="width: 1%;">:</td><td style="width: 30%; text-align: end;"><b>${Number(data[0].totordamt).toFixed(2)}</b></td></tr>    
                          </table>
                            <table style="">
                            <tr style="width: 100%; font-size: 19px;"><td style="width: 69%;"><b>Advance</b></td><td style="width: 1%;">:</td><td style="width: 30%; text-align: end;"><b>${Number(data[0].advanceamt).toFixed(2)}</b></td></tr>    
                          </table>
                            <table style="">
                            <tr style="width: 100%; font-size: 22px;"><td style="width: 69%;"><b>Payable Amount</b></td><td style="width: 1%;">:</td><td style="width: 30%; text-align: end;"><b>${Number(data[0].totordamt-data[0].advanceamt).toFixed(2)}</b></td></tr>    
                          </table>

                          </td>
         </tr">
          </table>
           ${Billamtinword}
           
           </br>
                </br>
                </br>
                </br>
           <table style="width: 205mm;border-bottom: 1px solid black;">
                <tr style="border-bottom: 1px solid black; width: 205mm;">
                        <td style="width: 75%;">
                            <b>CASHIER SIGNATURE</b>
                        </td>

                        <td style="width: 25%;">
                            <b>GUEST SIGNATURE</b>
                        </td>
                </tr">
           
          </table>
          
           ${Html_billprintlineTC1}
            ${Html_billprintlineTC2}
            ${Html_billprintlineTC3}
            ${Html_billprintlineTC4}
            ${Html_billprintlineTC5}
        </div>`;
    };
    useEffect(() => {
        fetchData();
    }, [usershopid]);

    const cmdEdit = (id) => {
        console.log("id");
        console.log(id);
        axios.get(`${hostlink}/bhbook/${id}`)
            .then((resp) => {
                let x = document.getElementById("viewform")
                x.style.display = "None"
                let y = document.getElementById("newform")
                y.style.display = "block"
                console.log(resp.data)
                setfrmdata(resp.data[0])
                console.log(resp.data[0].bhcode);
                onChangevalueRoom(resp.data[0].bhcode, resp.data[0].bhname);
            })
    }
    const cmdDelete = (shopvno, shopid) => {
        // if (status === 'Billed') {
        //     toast.error("Bill Generated, You Cant't Delete..")
        //     return false
        // }
        axios.delete(`${hostlink}/bhbook/${shopvno}/${shopid}`)
            .then((resp) => {
                toast.success("Delete Sucessfull")
                setTimeout(() => {
                    setitemdetailGridRefresh(true)
                }, 1000);
            })
            .catch((resp) => {
                console.log("Something Else")
            })
    }

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        setQuery(inputValue);
        filterData(inputValue);
        setListVisible(true);
        setSelectedIndex(-1);
    };

    const filterData = (query) => {
        if (!query) {
            setFilteredData([]);
            return;
        }

        const filtered = data.filter(item => {
            return item.itname && item.itname.toLowerCase().includes(query.toLowerCase());
        }).slice(0, 5); // Limit to first 5 records after filtering

        setFilteredData(filtered);
    };
    const saveroomservice = async (isprint) => {
        document.getElementById("cmdsave").disabled = true
        console.log(c_Grcode)
        if (c_GCHID == "0" || c_GCHID === undefined || c_GCHID === '') {
            toast.error("Please Select Banquet...")
            document.getElementById("cmdsave").disabled = false
            return false
        }
        const time = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
        if (itemdetailGrid.length === 0) {
            let newRecord = {
                booktime: time,
                fromshopid: usershopid,
                shopid: queryCondition,
                gstamt: 0,
                ittotal: 0,
                totqty: 0,
                totgst: 0,
                discperc: 0,
                discamt: 0,
                totdiscamt: 0,
                roundoff: 0,
                flatdiscount:flatdiscount,
                totordamt: Number(hallrent*noofguest) + (noofroom * roomrent) + Number(foodamount)-Number(flatdiscount),
                foodpackname: foodpackname,
                foodamount: foodamount,
                noofroom: noofroom,
                roomrent: roomrent,
                rcode: c_Grcode,
                roomnoview: c_Grcodename,
                bhcode: c_GCHID,
                bhname: c_Gname,
                checkindate: checkindate,
                resdate: resdate,
                guestname: guestname,
                guestmob: guestmob,
                guestemail: guestemail,
                guestadd: guestadd,
                guestadd2: guestadd2,
                guestcity: guestcity,
                guestpincode: guestpincode,
                noofguest: noofguest,
                hallrent: hallrent,
                advanceamt: advanceamt,
                refrence: refrence,
                remarks: remarks,
                payableamt: payableamt
            };
            itemdetailGrid.push(newRecord);
        } else {
            for (let i = 0; i < itemdetailGrid.length; i++) {
                itemdetailGrid[i].id = '';
                itemdetailGrid[i].booktime = time;
                itemdetailGrid[i].fromshopid = usershopid;
                itemdetailGrid[i].shopid = queryCondition;
                itemdetailGrid[i].gstamt = (((itemdetailGrid[i].qty * itemdetailGrid[i].rate) - ((itemdetailGrid[i].qty * itemdetailGrid[i].rate) * itemdetailGrid[i].discperc / 100)) * itemdetailGrid[i].gst / 100).toFixed(2);
                itemdetailGrid[i].ittotal = Number((((itemdetailGrid[i].qty * itemdetailGrid[i].rate)) - ((itemdetailGrid[i].qty * itemdetailGrid[i].rate) * itemdetailGrid[i].discperc / 100)) + ((((itemdetailGrid[i].qty * itemdetailGrid[i].rate) - ((itemdetailGrid[i].qty * itemdetailGrid[i].rate) * itemdetailGrid[i].discperc / 100)) * itemdetailGrid[i].gst / 100))).toFixed(2);
                itemdetailGrid[i].totqty = itemdetailGrid.reduce((total, resp) => total + parseFloat(resp.qty), 0);
                itemdetailGrid[i].totgst = Number(itemdetailGrid.reduce((total, resp) => total + parseFloat((((resp.qty * resp.rate) - ((resp.qty * resp.rate) * resp.discperc / 100)) * resp.gst / 100)), 0)).toFixed(2);
                itemdetailGrid[i].discperc = itemdetailGrid[i].discperc;
                itemdetailGrid[i].discamt = (Number(itemdetailGrid[i].qty) * Number(itemdetailGrid[i].rate)) * Number(itemdetailGrid[i].discperc) / 100;
                itemdetailGrid[i].totdiscamt = Number(itemdetailGrid.reduce((total, resp) => total + parseFloat(((((resp.qty * resp.rate) * resp.discperc / 100)))), 0)).toFixed(2);
                itemdetailGrid[i].roundoff = Number((Math.round(itemdetailGrid.reduce((total, resp) => total + parseFloat((Number(((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))) * Number(resp.gst) / 100) + Number((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))))), 0))) - (itemdetailGrid.reduce((total, resp) => total + parseFloat((Number(((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))) * Number(resp.gst) / 100) + Number((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))))), 0))).toFixed(2);
                itemdetailGrid[i].totordamt = Math.round(itemdetailGrid.reduce((total, resp) => total + parseFloat((Number(((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))) * Number(resp.gst) / 100) + Number((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))))), 0)) + (Number(hallrent) + Number(noofroom * roomrent) + Number(foodamount));
                itemdetailGrid[i].foodpackname = foodpackname
                itemdetailGrid[i].foodamount = foodamount
                itemdetailGrid[i].noofroom = noofroom
                itemdetailGrid[i].roomrent = roomrent
                itemdetailGrid[i].rcode = c_Grcode
                itemdetailGrid[i].roomnoview = c_Grcodename
                itemdetailGrid[i].bhcode = c_GCHID
                itemdetailGrid[i].bhname = c_Gname
                itemdetailGrid[i].checkindate = checkindate
                itemdetailGrid[i].resdate = resdate
                itemdetailGrid[i].guestname = guestname
                itemdetailGrid[i].guestmob = guestmob
                itemdetailGrid[i].guestemail = guestemail
                itemdetailGrid[i].guestadd = guestadd
                itemdetailGrid[i].guestadd2 = guestadd2
                itemdetailGrid[i].guestcity = guestcity
                itemdetailGrid[i].guestpincode = guestpincode
                itemdetailGrid[i].noofguest = noofguest
                itemdetailGrid[i].hallrent = hallrent
                itemdetailGrid[i].advanceamt = advanceamt
                itemdetailGrid[i].refrence = refrence
                itemdetailGrid[i].remarks = remarks
                itemdetailGrid[i].payableamt = payableamt
            }
        }
        console.log(itemdetailGrid);

        await axios.post(`${hostlink}/bhbook`, itemdetailGrid)
            .then((resp) => {
                if (isprint == 1) {
                    printdata(resp.data);
                }
                toast.success("Save Sucess..");
                setInterval(() => {
                    window.location.assign("/BanquetBook")
                }, 1000);
            })
            .catch((resp) => {
                console.log("Data Not Save..")
            })
    }
    const handleSelectItem = (item, index) => {
        setSelectedItem(item);
        setQuery(item.itname);
        setListVisible(false);
        setSelectedIndex(index);
    };
    const [itemdetail, setitemdetail] = useState({ rawcode: 0, rawname: "", barcode: "", qty: 1, rate: 0, discperc: 0, taxable: 0, gst: 0, gstamt: 0, ittotal: 0, totqty: 0, totgst: 0, totordamt: 0, rcode: "", roomnoview: "" });
    const { rawcode, rawname, barcode, qty, rate, discperc, taxable, gst, gstamt, ittotal } = itemdetail;
    const [itemdetailGrid, setitemdetailGrid] = useState([]);
    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
    }, [itemdetailGrid]);

    useEffect(() => {

        axios.get(`${hostlink}/${usershopid}/foodpack`)
            .then((resp) => {
                setFoodPackage(resp.data);
            });
        axios.get(`${hostlink}/api/executeQuery?sqlQuery=select bhbookmas.resdate,bhbookmas.booktime,checkindate,bhname,guestname,guestmob,bhbookmas.shopid,bhbookmas.shopvno,(select cname from shopmas where id = bhbookmas.fromshopid) as createfrom,Case when bhbookmas.status=0 then 'Pending' when bhbookmas.status=1 then 'Billed' When bhbookmas.status=2 then 'Cancel' end as statusname,id from bhbookmas where shopid = ${queryCondition} and resdate between '${fromdate}' and '${todate}' group by bhbookmas.resdate,bhbookmas.booktime,checkindate,bhname,guestname,guestmob,bhbookmas.shopid,bhbookmas.shopvno`)
            .then((Response) => {
                setsrvData(Response.data)
                setitemdetailGridRefresh(false)
            })
            .catch((Response) => {
                console.log("Data Not Fatched")
            })
    }, [itemdetailGridRefresh,usershopid]);
    const handleItemClick = (index) => {
        const queryCondition = filteredData[index].ispackage == 0
            ? filteredData[index].restrate
            : 0;

        setSelectedIndex(index);
        handleSelectItem(filteredData[index], index);
        setQuery(filteredData[index].itname);
        setitemdetail({
            ...itemdetail,
            rawcode: filteredData[index].id,
            rawname: filteredData[index].itname,
            barcode: filteredData[index].barcode,
            qty: 1,
            rate: queryCondition,
            discperc: filteredData[index].discperc,
            gst: filteredData[index].gst,
        });
        document.getElementById("qty").focus();
    };
    const handleKeyDown = (event) => {
        if (document.getElementById("inputname1111").value == "") {
            return false
        }
        if (event.key === 'ArrowDown') {
            event.preventDefault();
            setSelectedIndex(prevIndex => (prevIndex < filteredData.length - 1 ? prevIndex + 1 : prevIndex));
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            setSelectedIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
        } else if (event.key === 'Enter' && selectedIndex !== -1) {
            handleSelectItem(filteredData[selectedIndex], selectedIndex);
            setQuery(filteredData[selectedIndex].itname);
            const queryCondition = filteredData[selectedIndex].ispackage == 0
                ? filteredData[selectedIndex].restrate
                : 0;

            setitemdetail({
                ...itemdetail,
                rawcode: filteredData[selectedIndex].id,
                rawname: filteredData[selectedIndex].itname,
                barcode: filteredData[selectedIndex].barcode,
                discperc: filteredData[selectedIndex].discperc,
                qty: 1,
                rate: queryCondition,
                gst: filteredData[selectedIndex].gst,
            });
            document.getElementById("qty").focus();
        }
    };

    const handleFocus = (e) => {
        // setTimeout(() => {
        //     e.target.select(); // Select the input value when it receives focus
        // }, 0);
    };
    const handleKeyDownnext = (event) => {
        if (event.keyCode === 39) {
            event.preventDefault(); // Prevent cursor from moving to the end
            event.target.setSelectionRange(0, event.target.value.length); // Select the input value
        }
        if (event.keyCode === 13 || event.keyCode === 9) { // Check if the Enter key or Tab key is pressed
            event.preventDefault(); // Prevent default Tab behavior
            const interactiveElements = document.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
            const currentIndex = Array.from(interactiveElements).indexOf(event.target); // Get the index of the current interactive element
            if (currentIndex < interactiveElements.length - 1) {
                interactiveElements[currentIndex + 1].focus(); // Focus on the next interactive element
            } else if (event.keyCode === 9) {
                interactiveElements[0].focus();
            }
        }
    };

    const today = new Date();
    const [frmdata, setfrmdata] = useState({
        fromdate: today.toISOString().split('T')[0],
        todate: today.toISOString().split('T')[0],
        status: 0, foodpackname: "", foodamount: 0, noofroom: 0, roomrent: 0,flatdiscount:0,
        resdate: today.toISOString().split('T')[0], checkindate: today.toISOString().split('T')[0], guestname: "", guestmob: "", guestemail: "", guestadd: "", guestadd2: "", guestcity: "", guestpincode: "", noofguest: 100, hallrent: 0, advanceamt: 0, refrence: "", remarks: "", payableamt: 0
    });
    const { fromdate, todate, resdate, checkindate, guestname, guestmob, guestemail, guestadd, guestadd2, guestcity, guestpincode, noofguest, hallrent, advanceamt, refrence, foodpackname, remarks, payableamt, status, noofroom, flatdiscount,roomrent, foodamount } = frmdata;
    const handleKeyDownFindItem = (event) => {

        const existingItem = data.find(item => item.barcode === barcode);
        if (existingItem) {
            console.log("Item found:", existingItem);
            const queryCondition = filteredData[existingItem].ispackage == 0
                ? filteredData[existingItem].restrate
                : 0;

            setQuery(existingItem.itname)
            setitemdetail({
                ...itemdetail,
                rawcode: existingItem.id,
                rawname: existingItem.itname,
                barcode: existingItem.barcode,
                discperc: existingItem.discperc,
                qty: 1,
                rate: queryCondition,
                gst: existingItem.gst,
            });
        } else {
            setitemdetail({
                ...itemdetail,
                id: 0,
            });
            return false
        }


        if (event.keyCode === 13 || event.keyCode === 9) {
            const interactiveElements = document.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
            const currentIndex = Array.from(interactiveElements).indexOf(event.target);

            if (currentIndex < interactiveElements.length - 1) {
                interactiveElements[currentIndex + 1].focus();
                console.log("1")

            } else if (event.keyCode === 9) {
                interactiveElements[0].focus();
                console.log("2")
            }
        }
    };
    const [guestdata, setguestdata] = useState({ c_Gname: "", c_GMob: "", c_GAdd: "", c_Grcode: "", c_Grcodename: "", c_GCHID: "" });
    const { c_Gname, c_GMob, c_GAdd, c_Grcode, c_Grcodename, c_GCHID } = guestdata;
    const onChangevalueRoom = (e, f) => {
        const existingItem = Room.find(item => item.bhname === f);
        if (existingItem) {
            setguestdata({
                ...guestdata,
                c_GCHID: existingItem.id,
                c_Gname: existingItem.bhname,
            });
        } else {
            console.log("Item not found");
        }
    };



    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,<>?=]/g, '');
        setitemdetail({ ...itemdetail, [name]: sanitizedValue });
    }
    useEffect(() => {
        const existingItem = FoodPackage.find(item => item.fpname === foodpackname);
        if (existingItem) {
            console.log(existingItem)
            setfrmdata({ ...frmdata, foodamount: existingItem.rate });
        } else {
            setfrmdata({ ...frmdata, foodamount: 0 });
        }

    }, [foodpackname])
    const onChangevalueloaddata = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`#$%^&*+{}|;:,.<>?=]/g, '');
        setfrmdata({ ...frmdata, [name]: sanitizedValue });
        setitemdetailGridRefresh(true)
    }
    const [isVisible, setIsVisible] = useState(false);

    const [Room, setRoom] = useState([])
    const [items, setItems] = useState([]);
    const handleDoubleClick = (itemId) => {
        const selectedItem = items.find(item => item.id === itemId);
        if (selectedItem) {
            alert(`Double-clicked on: ${selectedItem.itname}`);
        }
    };
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    // Show button when user scrolls down 400px
    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
        axios.get(`${hostlink}/${usershopid}/item`)
            .then((Response) => {
                setItems(Response.data)
            })
            .catch((Response) => {
                console.log("Data Not Fatched")
            })

        axios.get(`${hostlink}/${queryCondition}/bhmas`)
            .then((Response) => {
                setRoom(Response.data)
                console.log(Response.data[0].bhname)
            })
            .catch((Response) => {
                console.log("Data Not Fatched")
            })

        const toggleVisibility = () => {
            if (window.pageYOffset > 100) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, [usershopid]);

    const handleDeleteItem = async (id) => {
        const updatedItDetails = itemdetailGrid.filter(item => item.rawcode !== id);
        toast.success("Item deleted");
        setitemdetailGrid(updatedItDetails);
        document.getElementById("inputname1111").focus();
    };

    const handleAddItem = () => {

        if (rawcode == 0) {
            toast.error("Please Select Item..");
            document.getElementById("inputname1111").focus();
            return false
        }
        const existingItem = itemdetailGrid.find(item => item.rawcode === rawcode);
        if (existingItem) {
            const updatedItDetails = itemdetailGrid.map(item => {
                if (item.rawcode === rawcode) {
                    return { ...item, qty: Number(Number(item.qty) + Number(qty)).toFixed(2) };
                }
                return item;
            });
            setitemdetailGrid(updatedItDetails);
            toast.success("quantity added..");
            setQuery('')
            setitemdetail({
                ...itemdetail,
                rawcode: 0,
                rawname: "",
                barcode: "",
                qty: "",
                discperc: 0,
                rate: "",
                gst: "",
            });
            document.getElementById("inputname1111").focus();
        } else {
            setitemdetailGrid([...itemdetailGrid, {
                rawcode: rawcode,
                rawname: rawname,
                barcode: barcode,
                discperc: discperc,
                qty: qty,
                rate: rate,
                gst: gst,
            }])
            toast.success("Item added..");
            setQuery('')
            setitemdetail({
                ...itemdetail,
                rawcode: 0,
                rawname: "",
                barcode: "",
                qty: "",
                rate: "",
                gst: "",
            });
            document.getElementById("inputname1111").focus();
        }
    };
    const cmdNew = () => {
        seteditDeleteAPi('')
        let x = document.getElementById("viewform")
        x.style.display = "None"
        let y = document.getElementById("newform")
        y.style.display = "block"
    }
    const searchData = (e) => {
        const searchValue = document.getElementById("searchroom").value.trim();
        console.log(searchValue)
        if (searchValue === "") {
            axios.get(`${hostlink}/getchehinroomservice/${usershopid}/${fromdate}/${todate}`)
                .then((Response) => {
                    setsrvData(Response.data)
                    setitemdetailGridRefresh(false)
                })
                .catch(() => { })
        } else {
            axios.get(`${hostlink}/getchehinroomservice/search/${usershopid}/${fromdate}/${todate}/${searchValue}`)
                .then((Response) => {
                    setsrvData(Response.data)
                    setitemdetailGridRefresh(false)
                })
                .catch(() => { })
        }
    }
    return (


        <div>
            <div id='viewform'>
                <div className='container p-2'>
                    <div className='row p-1'>
                        <div className='col-md-4 p-1'>
                            <h5 className='font-monospace'><i class="fa-solid fa-warehouse"></i> Banquet</h5>
                        </div>
                        {/* <div className='col-md-2 rounded' style={{ display: "flex", alignItems: "center", background: "rgb(245,245,246)", height: "40px" }}>
                            <i class="fa-solid fa-magnifying-glass " ></i> &nbsp;&nbsp; <input type="text" class="" name="searchroom" onChange={(e) => { searchData(e) }} id="searchroom" placeholder="Search Room" style={{ background: "rgb(245,245,246)", outline: "none", border: "0", width: "100%" }} /> <br />
                        </div> */}

                        <div className='col-md-6 align-self-end' style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                            <span className="f-size-on-mobile align-self-end" style={{ verticalAlign: 'middle', height: '100%', }}>
                                <label htmlFor="todate" className="form-label text-center">From : &nbsp;</label>
                            </span>
                            <span> <input type="date" id="fromdate" name="fromdate" onChange={(e) => { onChangevalueloaddata(e) }} value={fromdate} className="form-control" /></span>


                            <span className="f-size-on-mobile align-self-end">
                                <label htmlFor="todate" className="form-label text-center">&nbsp; To : &nbsp;</label>
                            </span>
                            <span>
                                <input type="date" id="todate" name="todate" onChange={(e) => { onChangevalueloaddata(e) }} value={todate} className="form-control" />
                            </span>
                        </div>


                        <div className='col-md-2 text-end'>
                            {(userType === 0 || appState[1]?.[0]?.itemmaster?.charAt(1) === '1') ? ( <button id='cmdnew' onClick={cmdNew} className='btn btn-outline-primary'> <i class="fa-solid fa-plus"></i> &nbsp;Reservation </button>) : null}
                        </div>
                        <div style={{ maxHeight: "80vh", overflow: "auto" }} className='col-12 '>
                            <table className="table table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col" className="f-size-on-mobile"><span className='hide-on-mobile'>Res Date</span> <span className='show-on-mobile'>Details</span></th>
                                        <th scope="col" className="hide-on-mobile">Time</th>
                                        <th scope="col" className="hide-on-mobile">Check-In</th>
                                        <th scope="col" className="hide-on-mobile">Hall Name</th>
                                        <th scope="col" className="hide-on-mobile">Book By</th>
                                        <th scope="col" className="hide-on-mobile">Guest Name</th>
                                        <th scope="col" className="hide-on-mobile">Mob No</th>
                                        {/* <th scope="col" className="hide-on-mobile">Bill Status</th> */}
                                        <th scope="col" className="f-size-on-mobile text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {srvdata.map((res, x) => (
                                        <tr style={{ verticalAlign: 'middle', height: '100%' }}>
                                            <td className="f-size-on-mobile" style={{ wordWrap: "break-word" }}> <span className='show-on-mobile'><b>Date :</b> </span> {res[0] ? new Date(res[0]).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"} <br />
                                                <div className="detail-on-mobile text-start align-self-start" style={{ fontSize: "12px", color: "blue", alignItems: "start", wordWrap: "break-word" }}>
                                                    {res[1] !== 0 && (<>Time : {res[1] ? new Date(`2022-01-01T${res[1]}`).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }) : "-"} <br /></>)}
                                                    {res[10] !== 0 && (<>Check-In : {res[2] ? new Date(res[2]).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"} <br /></>)} <span style={{ color: "blue" }}> </span>
                                                    <span>{res[0] !== '' && (<>Name : {res[4]} <br /></>)}</span>
                                                    <span>{res[0] !== '' && (<>Mob No : {res[5]} <br /></>)}</span>

                                                    <span>{res[0] !== '' && (<>Book By : {res[8]} <br /></>)}</span>
                                                    <span className='text-bg-danger'>{res[0] !== '' && (<>Hall Name : {res[3]} <br /></>)}</span>
                                                </div>
                                            </td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res[1]}</td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res[2] ? new Date(res[2]).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"}</td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res[3]}</td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res[8]}</td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res[4]}</td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res[5]}</td>
                                            {/* <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res[9]}</td> */}
                                            <td className='text-center' style={{ verticalAlign: 'middle', height: '100%' }}>
                                                {/* <Link to={"/BanquetBook"} onClick={(e) => { cmdEdit(res[10]) }} className='btn btn-sm btn-outline-dark'><i class="fa-solid fa-pen-to-square"></i><span className="hide-on-mobile" ></span></Link> &nbsp; <span className="detail-on-mobile"><br /> <br /></span> */}
                                                <Link className='btn btn-sm btn-outline-primary' onClick={(e) => { printdata(res[10]) }}><i class="fa-solid fa-print"></i> <span className="hide-on-mobile"></span></Link> &nbsp;
                                                {/* <Link className='btn btn-sm btn-outline-dark' onClick={(e) => { printdata(res[10]) }}><i class="fa-solid fa-file-invoice"></i> <span className="hide-on-mobile"></span></Link> &nbsp; */}
                                                {(userType === 0 || appState[1]?.[0]?.itemmaster?.charAt(3) === '1') ? (<Link className='btn btn-sm btn-danger' onClick={(e) => { cmdDelete(res[7], res[6]) }} > <i class="fa-solid fa-trash"></i> <span className="hide-on-mobile"></span></Link> ) : null} &nbsp;
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {/* New Service */}
            <div id='newform' style={{ display: "none" }}>
                <div className='container-fluid py-1 '>
                    <div className='row py-1 shadow rounded' style={{ backgroundImage: 'linear-gradient(to right, #4DD6F4, #ff9999, #4DD6F4)', color: 'white' }}>
                        <div className="col-md-3 align-self-center"><h4> &nbsp;<i class="fa-solid fa-warehouse"></i> Banquet Reservation</h4></div>
                        <div className="col-4 text-end align-self-center"><label for="inputState" class="form-label"> Banquet Hall</label></div>
                        <div class="col-2 align-self-center">
                            <select id="roomcode" ref={roomCodeRef} onChange={(e) => { onChangevalueRoom(e.target.value, e.target.selectedOptions[0].text) }} class="form-select" style={{ width: "200px" }}>
                                <option selected key={0} value={0}>Choose...</option>
                                {Room.map((x) => {
                                    return (
                                        <option key={x.id} value={x.id} title={x.id}> {x.bhname} </option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                    <p></p>
                    <div className="row">
                        <div class="form-group col-md-2">
                            <label for="inputEmail4">Reservation Date</label>
                            <input type="date" class="form-control" id="resdate" name="resdate" onChange={(e) => { onChangevalueloaddata(e) }} value={resdate} />
                        </div>
                        <div class="form-group col-md-2">
                            <label for="inputEmail4">Check-in Date</label>
                            <input type="date" class="form-control" id="checkindate" name="checkindate" onChange={(e) => { onChangevalueloaddata(e) }} value={checkindate} />
                        </div>

                        <div className="col-md-12">
                            <div class="row">
                                <div class="form-group col-md-4">
                                    <label for="inputEmail4">Name</label>
                                    <input type="name" class="form-control" id="guestname" name="guestname" onChange={(e) => { onChangevalueloaddata(e) }} value={guestname} />
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="inputEmail4">Mobile No</label>
                                    <input type="Mobile" class="form-control" id="guestmob" name="guestmob" onChange={(e) => { onChangevalueloaddata(e) }} value={guestmob} />
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="inputEmail4">Address</label>
                                    <input type="Address" class="form-control" id="guestadd" name="guestadd" onChange={(e) => { onChangevalueloaddata(e) }} value={guestadd} />
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="inputEmail4">Address 2</label>
                                    <input type="Address2" class="form-control" id="guestadd2" name="guestadd2" onChange={(e) => { onChangevalueloaddata(e) }} value={guestadd2} />
                                </div>
                                <div class="form-group col-md-2">
                                    <label for="inputCity">City</label>
                                    <input type="text" class="form-control" id="guestcity" name="guestcity" onChange={(e) => { onChangevalueloaddata(e) }} value={guestcity} />
                                </div>
                                <div class="form-group col-md-2">
                                    <label for="inputZip">Pin Code</label>
                                    <input type="text" class="form-control" id="guestpincode" name="guestpincode" onChange={(e) => { onChangevalueloaddata(e) }} value={guestpincode} />
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="inputEmail4">Email</label>
                                    <input type="email" class="form-control" id="guestemail" name="guestemail" onChange={(e) => { onChangevalueloaddata(e) }} value={guestemail} />
                                </div>
                                <div class="form-group col-md-2">
                                    <label for="inputCity">No of Guest</label>
                                    <input type="text" class="form-control" id="noofguest" name="noofguest" onChange={(e) => { onChangevalueloaddata(e) }} value={noofguest} />
                                </div>
                                <div class="form-group col-md-2">
                                    <label for="inputCity">Rent</label>
                                    <input type="text" class="form-control" id="hallrent" name="hallrent" onChange={(e) => { onChangevalueloaddata(e) }} value={hallrent} />
                                </div>
                                <div class="form-group col-md-2">
                                    <label for="inputZip">Advance</label>
                                    <input type="text" class="form-control" id="advanceamt" name="advanceamt" onChange={(e) => { onChangevalueloaddata(e) }} value={advanceamt} />
                                </div>
                                <div class="form-group col-md-2">
                                    <label for="inputZip">Refrence</label>
                                    <input type="text" class="form-control" id="refrence" name="refrence" onChange={(e) => { onChangevalueloaddata(e) }} value={refrence} />
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="inputZip">Remarks</label>
                                    <input type="text" class="form-control" id="remarks" name="remarks" onChange={(e) => { onChangevalueloaddata(e) }} value={remarks} />
                                </div>

                                <div class="col-md-3">
                                    <label for="inputZip">Food Package</label>
                                    <select id="foodpackname" onChange={(e) => { onChangevalueloaddata(e); }} name='foodpackname' value={foodpackname} class="form-select">
                                        <option selected key={0} value={0}>NA</option>
                                        {FoodPackage.map((x) => {
                                            return (
                                                <option key={x.id} value={x.fpname} title={x.fpname}> {x.fpname} </option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div class="form-group col-md-1">
                                    <label for="inputZip">Amount</label>
                                    <input type="text" class="form-control" id="foodamount" name="foodamount" onChange={(e) => { onChangevalueloaddata(e) }} value={foodamount} />
                                </div>

                                <div class="form-group col-md-1">
                                    <label for="inputZip">No of Room</label>
                                    <input type="text" class="form-control" id="noofroom" name="noofroom" onChange={(e) => { onChangevalueloaddata(e) }} value={noofroom} />
                                </div>

                                <div class="form-group col-md-1">
                                    <label for="inputZip">Room Rent</label>
                                    <input type="text" class="form-control" id="roomrent" name="roomrent" onChange={(e) => { onChangevalueloaddata(e) }} value={roomrent} />
                                </div>
                                <div class="form-group col-md-2">
                                    <label for="inputZip">Total Room Rent</label>
                                    <input type="text" class="form-control" id="foodamount" name="foodamount" onChange={(e) => { onChangevalueloaddata(e) }} value={Number(roomrent * noofroom)} />
                                </div>

                                <div class="form-group col-md-2">
                                    <label for="inputZip">Flat Discount</label>
                                    <input type="text" class="form-control" id="flatdiscount" name="flatdiscount" onChange={(e) => { onChangevalueloaddata(e) }} value={flatdiscount} />
                                </div>

                                

                            </div>
                            <br />
                            {/* <div className="row bg-dark align-self-center text-center text-bg-dark border border-1">
                                <div className="col-md-12"><h5 className='align-bottom text-center'><b>Select Dish Item</b></h5></div>
                            </div>

                            <div className="row py-1">
                                <div className="col-md-1"></div>
                                <div className="col-md-3">
                                    <label className='font-monospace' class="form-label">Item Name</label>
                                    <input type="text" placeholder="Search for items..." class="form-control" value={query} id="inputname1111" onFocus={handleFocus} onChange={handleInputChange} onKeyDown={handleKeyDown} ref={inputRef} /> <br />
                                    {listVisible && filteredData.length > 0 && (
                                        <ul style={{}}>
                                            {filteredData.map((item, index) => (
                                                <li
                                                    key={item.id}
                                                    onClick={() => handleSelectItem(item, index)}
                                                    onMouseDown={() => handleItemClick(index)}

                                                    className={index === selectedIndex ? 'selected' : ''}
                                                >
                                                    {item.itname} - ₹{item.restrate}
                                                </li>
                                            ))}
                                        </ul>
                                    )}

                                </div>
                                <div className="col-md-2">
                                    <label className='font-monospace' class="form-label">Barcode</label>
                                    <input type="text" value={barcode} onKeyDown={handleKeyDownFindItem} onFocus={handleFocus} onChange={(e) => { onChangevalue(e) }} name='barcode' class="form-control" id="barcode" /> <br />
                                </div>
                                <div className="col-md-1">
                                    <label className='font-monospace' class="form-label">Qty</label>
                                    <input type="text" value={qty} name='qty' onKeyDown={handleKeyDownnext} onFocus={handleFocus} onChange={(e) => { onChangevalue(e) }} class="form-control" id="qty" /> <br />
                                </div>
                                <div className="col-md-1">
                                    <label className='font-monospace' class="form-label">Rate</label>
                                    <input type="text" value={rate} name='rate' onKeyDown={handleKeyDownnext} onFocus={handleFocus} onChange={(e) => { onChangevalue(e) }} class="form-control" id="rate" /> <br />
                                </div>
                                <div className="col-md-1">
                                    <label className='font-monospace' class="form-label">Dis %</label>
                                    <input type="text" value={discperc} name='discperc' onKeyDown={handleKeyDownnext} onFocus={handleFocus} onChange={(e) => { onChangevalue(e) }} class="form-control" id="discperc" /> <br />
                                </div>
                                <div id='addroomNew' className="col-md-2 text-center">
                                    <label className='font-monospace ' style={{ color: "white" }} class="form-label">-</label> <br />
                                    <button style={{ backgroundImage: 'linear-gradient(to right, #ff9999, #ff9999, #ff9999)', color: 'black', fontSize: "12px", width: "90%", textAlign: "center", justifyContent: "center" }} type="submit" onClick={() => { handleAddItem() }} class="btn btn-info"><i class="fa-solid fa-circle-plus"></i> Add</button>
                                </div>

                            </div>

                            <div className='show-on-mobile row   container' style={{ color: 'black' }}>
                                {itemdetailGrid.map((resp, index) => (
                                    <div key={index} className='row text-center shadow rounded align-self-center p-1 m-0 bg-light border border-1'>
                                        <h6 style={{ "color": "blue", borderBottom: "1px solid black" }}>Item : {resp.rawname}</h6>
                                        <div className='col-9 text-start' style={{ fontSize: "15px", color: "black" }}>
                                            <table style={{ "width": "100%" }}>
                                                <tr><td>Qty </td><td>: {resp.qty}</td></tr>
                                                <tr><td>Rate</td><td>: {resp.rate}</td></tr>
                                                <tr><td>Gst {resp.gst} % </td><td>: {((resp.qty * resp.rate) * resp.gst / 100)}</td></tr>
                                                <tr><td>Total</td><td>: {(resp.qty * resp.rate) + ((resp.qty * resp.rate) * resp.gst / 100)}</td></tr>
                                            </table>
                                        </div>
                                        <div className='col-3 align-self-center'>
                                            <button type="submit" onClick={(e) => { handleDeleteItem(resp.rawcode) }} className="btn btn-outline-danger"> <i className="fa-solid fa-trash"></i></button>
                                        </div>

                                    </div>
                                ))}
                            </div>

                            <div className='hide-on-mobile text-left align-self-center row bg-info p-1 m-0 shadow rounded border border-1' style={{ backgroundImage: 'linear-gradient(to right, #4DD6F4, #ff9999, #4DD6F4)', color: 'black', padding: '20px' }}>
                                <div className='row text-left align-self-center p-1 m-0 '>
                                    <div className='col-md-3'>Item Name</div>
                                    <div className='col-md-1'>Barcode</div>
                                    <div className='col-md-1 text-end'>Qty</div>
                                    <div className='col-md-1 text-end'>Rate</div>
                                    <div className='col-md-1 text-end'>Dis %</div>
                                    <div className='col-md-1 text-end'>Dis Amt</div>
                                    <div className='col-md-1 text-end'>Gst</div>
                                    <div className='col-md-1 text-end'>Gst Amt</div>
                                    <div className='col-md-1 text-end'>Amount</div>
                                    <div className='col-md-1 text-end'>Action</div>
                                </div>
                            </div>
                            <div className='hide-on-mobile' style={{ maxHeight: "365px", overflow: "auto" }} ref={scrollRef}>
                                {itemdetailGrid.map((resp, index) => (
                                    <div key={index} className='row text-left p-1 m-0 bg-light border border-1'>
                                        <div className='col-md-3 align-self-center'>{resp.rawname}</div>
                                        <div className='col-md-1 align-self-center'>{resp.barcode}</div>
                                        <div className='col-md-1 align-self-center text-end'>{Number(resp.qty).toFixed(2)}</div>
                                        <div className='col-md-1 align-self-center text-end'>{resp.rate}</div>
                                        <div className='col-md-1 align-self-center text-end'>{resp.discperc}</div>
                                        <div className='col-md-1 align-self-center text-end'>{((resp.qty * resp.rate) * resp.discperc / 100).toFixed(2)}</div>
                                        <div className='col-md-1 align-self-center text-end'>{resp.gst}</div>
                                        <div className='col-md-1 align-self-center text-end'>{(((resp.qty * resp.rate) - ((resp.qty * resp.rate) * resp.discperc / 100)) * resp.gst / 100).toFixed(2)}</div>
                                        <div className='col-md-1 align-self-center text-end'>{Number((((resp.qty * resp.rate)) - ((resp.qty * resp.rate) * resp.discperc / 100)) + ((((resp.qty * resp.rate) - ((resp.qty * resp.rate) * resp.discperc / 100)) * resp.gst / 100))).toFixed(2)}</div>
                                        <div className='col-md-1 align-self-center text-end'>
                                            <button type="submit" onClick={(e) => { handleDeleteItem(resp.rawcode) }} className="btn btn-outline-danger"> <i className="fa-solid fa-trash"></i></button>
                                        </div>
                                    </div>
                                ))}
                                <div className=' bg-dark text-bg-dark row text-left p-1 m-0 bg-light border border-1'>
                                    <div className='col-md-3 align-self-center'></div>
                                    <div className='col-md-1 align-self-center text-end'><b>Total :</b></div>
                                    <div className='col-md-1 align-self-center  text-end'><b>{Number(itemdetailGrid.reduce((total, resp) => total + parseFloat(resp.qty), 0)).toFixed(2)}</b></div>
                                    <div className='col-md-1 align-self-center  text-end'></div>
                                    <div className='col-md-1 align-self-center  text-end'></div>
                                    <div className='col-md-1 align-self-center  text-end'></div>
                                    <div className='col-md-1 align-self-center  text-end'></div>
                                    <div className='col-md-1 align-self-center  text-end'><b>{Number(itemdetailGrid.reduce((total, resp) => total + parseFloat((((resp.qty * resp.rate) - ((resp.qty * resp.rate) * resp.discperc / 100)) * resp.gst / 100)), 0)).toFixed(2)}</b></div>
                                    <div className='col-md-1 align-self-center  text-end'><b>{Number(itemdetailGrid.reduce((total, resp) => total + parseFloat((
                                        Number(((((resp.qty * resp.rate))) -
                                            (((resp.qty * resp.rate * resp.discperc) / 100))) * Number(resp.gst) / 100) +

                                        Number((((resp.qty * resp.rate))) -
                                            (((resp.qty * resp.rate * resp.discperc) / 100)))

                                    )), 0)).toFixed(2)}</b></div>
                                    <div className='col-md-1 align-self-center'>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className="col-md-3 ">
                        </div>
                    </div>
                </div>
                <br /><br /><br /><br />
                <div className="parent-container">
                    {/* Fixed element at the bottom */}
                    <div className="fixed-bottom" style={{ position: 'fixed', bottom: 0, width: '100%', backgroundColor: '#fff', borderTop: '1px solid black' }}>

                        <div className="container-fluid" >

                            <div className="row">
                                <div className="col-md-9 f-size-on-mobile text-end p-1" style={{ borderRight: "1px solid black" }}>
                                    <p className='hide-on-mobile m-2'></p>
                                    <button id='cmdsave' onClick={() => { saveroomservice(1) }} class="btn btn-primary">Save & Print</button>  &nbsp;
                                    <button id='cmdsave' onClick={() => { saveroomservice(0) }} class="btn btn-primary">Save</button>  &nbsp;
                                    <a href='/BanquetBook' class="btn btn-outline-primary">Cancel</a>
                                </div>
                                {/* Summary */}
                                <div className="col-md-3 f-size-on-mobile ">
                                    <div className='rounded'>
                                        <div className="row p-1 m-1 f-size-on-mobile">
                                            {/* <div className="col-6 text-end size-on-mobile" style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold', color: "blue" }}> Rent :</div> <div style={{ fontFamily: 'arial', color: "blue", fontSize: '14px', fontWeight: 'bold' }} className="col-6 text-end">  {Number((Math.round(itemdetailGrid.reduce((total, resp) => total + parseFloat((Number(((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))) * Number(resp.gst) / 100) + Number((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))))), 0))) - (itemdetailGrid.reduce((total, resp) => total + parseFloat((Number(((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))) * Number(resp.gst) / 100) + Number((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))))), 0))).toFixed(2)} </div> */}
                                            {/* <div className="col-6 text-end size-on-mobile" style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold', color: "blue" }}> Round-off :</div> <div style={{ fontFamily: 'arial', color: "blue", fontSize: '14px', fontWeight: 'bold' }} className="col-6 text-end">  {Number((Math.round(itemdetailGrid.reduce((total, resp) => total + parseFloat((Number(((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))) * Number(resp.gst) / 100) + Number((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))))), 0))) - (itemdetailGrid.reduce((total, resp) => total + parseFloat((Number(((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))) * Number(resp.gst) / 100) + Number((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))))), 0))).toFixed(2)} </div> */}
                                            <div className="col-6 text-end size-on-mobile" style={{ fontFamily: 'arial', fontSize: '18px', fontWeight: 'bold', backgroundColor: "black", color: "white" }}> Total :</div> <div style={{ fontFamily: 'arial', backgroundColor: "black", color: "white", fontSize: '16px', fontWeight: 'bold' }} className="col-6 text-end"> {Math.round(itemdetailGrid.reduce((total, resp) => total + parseFloat((Number(((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))) * Number(resp.gst) / 100) + Number((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))))), 0)) + (Number(hallrent*noofguest) + Number(noofroom * roomrent) + Number(foodamount))-Number(flatdiscount)} </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>


            </div>
            <ToastContainer autoClose={1000}></ToastContainer>
        </div>
    )
}

export default BanquetBook