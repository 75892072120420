import React, { useEffect, useRef, useState, useContext } from 'react';
import axios from 'axios';
import hostlink from '../Hostlink/hostlink';
import * as XLSX from 'xlsx';
import { PDFDownloadLink, PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { UserLoginDetails } from '../Hostlink/UserLoginDetails';

const Rpt_Kot_Item_Cancel = () => {
    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;
    const [RoomType, setRoomType] = useState([]);
    const [Room, setRoom] = useState([]);
    const [data, setData] = useState([]);
    const today = new Date();
    const [frmdata, setfrmdata] = useState({
        fromdate: today.toISOString().split('T')[0],
        todate: today.toISOString().split('T')[0],
        status: 0
    });
    const [totalSums, setTotalSums] = useState({});

    const { fromdate, todate, status } = frmdata;
    const tableRef = useRef(null);
    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setfrmdata({ ...frmdata, [name]: sanitizedValue });
    };
    const loaddata = async () => {
        try {
            console.log(`${hostlink}/CancelledItem/${usershopid}/${fromdate}/${todate}`)
            const billResponse = await axios.get(`${hostlink}/CancelledItem/${usershopid}/${fromdate}/${todate}`);
            const billData = billResponse.data;
            setData(billResponse.data)
        } catch (error) {
            console.log("Data Not Found...");
        }
    };


    const exportToExcel = () => {
        const ws = XLSX.utils.table_to_sheet(tableRef.current);
        const boldAndColorStyle = {
            font: { bold: true },
            fill: { fgColor: { rgb: "FFFF00" } }
        };
        const headerRow = XLSX.utils.decode_range(ws['!ref']).e.c;
        for (let c = 0; c <= headerRow; c++) {
            const cellAddress = XLSX.utils.encode_cell({ r: 0, c });
            if (ws[cellAddress].v === 'Sr No') {
                ws[cellAddress].s = boldAndColorStyle;
                for (let r = 1; r <= XLSX.utils.decode_range(ws['!ref']).e.r; r++) {
                    const dataCellAddress = XLSX.utils.encode_cell({ r, c });
                    if (ws[dataCellAddress]) {
                        ws[dataCellAddress].s = boldAndColorStyle;
                    }
                }
                break;
            }
        }
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        XLSX.writeFile(wb, 'Cancelled Item (KOT) Report.xlsx');
    };

    return (
        <div className='m-1'>
            <div className='container p-3 shadow rounded' style={{ transition: 'box-shadow 0.3s' }}>
                <div className='row'>
                    <div className='col-md-3 align-self-center'>
                        <h5 className='font-monospace text-primary'> <i class="fa-solid fa-right-from-bracket"></i> <span id='pagemode'> Cancelled Item (KOT)</span></h5>
                    </div>
                    <div className='col-md-1 align-self-center text-center'>
                        <label htmlFor="fromdate" className="form-label">From Date:</label>
                    </div>
                    <div className='col-md-2'>
                        <input type="date" id="fromdate" name='fromdate' onChange={(e) => { onChangevalue(e) }} value={fromdate} className="form-control" />
                    </div>
                    <div className='col-md-1 align-self-center text-center'>
                        <label htmlFor="todate" className="form-label">To Date:</label>
                    </div>
                    <div className='col-md-2'>
                        <input type="date" id="todate" name='todate' onChange={(e) => { onChangevalue(e) }} value={todate} className="form-control" />
                    </div>
                    
                    <div className='col-md-12 align-self-center text-end mt-2'>
                        <button onClick={() => { loaddata(); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-magnifying-glass-chart"></i> Show</button>
                        <button onClick={() => { exportToExcel(); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-table"></i> Excel</button>
                        <PDFDownloadLink document={<PDFDocument data={data} fromdate={fromdate} todate={todate} />} fileName="Cancelled Item (KOT) Report.pdf">
                            {({ blob, url, loading, error }) =>
                                loading ? 'Loading' : <button className='btn btn-sm btn-outline-primary' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-regular fa-file-pdf"></i> PDF</button>
                            }
                        </PDFDownloadLink>
                    </div>
                </div>
            </div>


            <br />
            {/* maxHeight: "400px", */}
            <div className='container-fluid' style={{  overflow: "auto" }}>
                <table className="table table-hover table-striped " ref={tableRef}>

                    <thead className='text-center' >
                        <tr >
                            <th scope="col">Kot No</th>
                            <th scope="col">Kot Date</th>
                            <th scope="col">Table No</th>
                            <th scope="col">Item Name</th>
                            <th scope="col">Waiter</th>
                            <th scope="col">Remarks</th>
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        {data.map((res, x) => (
                            <tr key={x} >
                                <td>{res.shopvno}</td>
                                <td>{res.kotdate ? new Date(res.kotdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</td>
                                <td>{res.tablename}</td>
                                <td>{res.itname}</td>
                                <td>{res.wname}</td>
                                <td>{res.itemremarks}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
const PDFDocument = ({ data, fromdate, todate }) => {
    // Calculate total bill amount
    return (
        <Document>
            <Page orientation="landscape">
                <View style={styles.container}>
                    <Text style={styles.title}>Cancelled Item (KOT)</Text>
                    <Text style={styles.title}>From : {fromdate} To : {todate}</Text>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <Text style={styles.headerCell}>Kot No</Text>
                            <Text style={styles.headerCell}>Kot Date</Text>
                            <Text style={styles.headerCell}>Table No</Text>
                            <Text style={styles.headerCell}>Item</Text>
                            <Text style={styles.headerCell}>Waiter</Text>
                            <Text style={styles.headerCell}>Remarks</Text>
                        </View>
                        {data.map((res, x) => (
                            <View key={x} style={styles.tableRow}>
                                <Text style={styles.cell}>{res.shopvno}</Text>
                                <Text style={styles.cell}>{res.kotdate ? new Date(res.kotdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</Text>
                                <Text style={styles.cell}>{res.tablename}</Text>
                                <Text style={styles.cell}>{res.itname}</Text>
                                <Text style={styles.cell}>{res.wname}</Text>
                                <Text style={styles.cell}>{res.itemremarks}</Text>
                            </View>
                        ))}
                        
                    </View>
                </View>
            </Page>
        </Document>
    );
};

const styles = StyleSheet.create({
    container: {
        padding: 20,
    },
    title: {
        fontSize: 16,
        fontStyle: 'Times New Roman',
        marginBottom: 20,
        textAlign: 'center',
    },
    table: {
        display: 'table',
        width: '100%',
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderColor: '#000',
    },
    headerCell: {
        fontSize: 8,
        fontWeight: 'bold',
        padding: 5,
        flex: 1,
        fontStyle: 'Times New Roman',
        textAlign: 'center',
    },
    cell: {
        fontSize: 8,
        padding: 5,
        flex: 1,
        fontStyle: 'Times New Roman',
        textAlign: 'center',
    },
});

export default Rpt_Kot_Item_Cancel