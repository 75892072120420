import axios, { Axios } from 'axios'
import React, { useEffect, useState, useContext } from 'react'
import hostlink from '../Hostlink/hostlink'
import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import usercontrol from '../Hostlink/usercontrol'
import Modal from 'react-modal';
import './MessageBox.css'; // 
import Loading from './Loading'
import { UserLoginDetails } from '../Hostlink/UserLoginDetails'

const BanquetHall = () => {
    const [isLoading, setIsLoading] = useState(false);
    
    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;
    const usershopidref = appState?.[0]?.hotelshopid || 0;
    

    const queryCondition = usershopidref == 0
                ? `${usershopid}`
                : `${usershopidref}`;
        
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [messagedata, setMessagedata] = useState({
        deleteid: "",
        deletemsg: ""
    })
    const { deleteid, deletemsg } = messagedata;


    const handleOpenModal = (e, flname) => {
        setMessagedata({ ...messagedata, deleteid: e, deletemsg: flname })
        setIsModalOpen(true);
    };
    const handleCloseModal = () => { setIsModalOpen(false); };
    const handleNoClick = () => { console.log('User clicked No'); handleCloseModal(); };

    const api = "/bhmas";
    const [pagemode, setPagemode] = useState(0)
    const [data, setData] = useState([])
    const [refreshData, setRefreshData] = useState(false)

    const [logdetail, setlogdetail] = useState({
        onform: "BanquetHall",
        operation: "",
        newdesc: "",
        shopid: usershopid,
        olddesc: "",
        usercode: usercontrol.user_code,
        computername: usercontrol.user_computer
    })
    const [Floor, setFloor] = useState([])
    const [RoomTYpe, setRoomTYpe] = useState([])
    const Closeform = () => {
        window.location.assign("/BanquetHall")
    }
    const searchData = (e) => {
        const searchValue = document.getElementById("search").value.trim();
        if (searchValue === "") {
            setRefreshData(true)
        } else {
            console.log(`${hostlink}/${usershopid}/roomstatus/0/0/3/${document.getElementById("search").value}`)
            axios.get(`${hostlink}/${usershopid}/roomstatus/0/0/3/${document.getElementById("search").value}`)
                .then((Response) => { setData(Response.data) })
                .catch(() => { })
        }
    }

    const SaveData = async (e) => {
        const myPckartUser = localStorage.getItem('MYPCKARTUSER'); if (!myPckartUser) { toast.error("Please Login First.."); return false };
        const inputValue = document.getElementById("inputname").value.trim();
        if (inputValue === "") {
            toast.error("Please Enter Name")
            return false
        }
        try {
            if (document.getElementById("pagemode").innerHTML == ' Table [ NEW ]') {
                var ld = { ...savedata, shopid: usershopid, narration: usershopid + document.getElementById("inputname").value.trim() }
                await axios.post(`${hostlink}${api}`, ld)
                    .then((Response) => {
                        toast("Save Sucessfull")
                        console.log(savedata)
                        var ld = { ...logdetail, newdesc: "Name : " + `${savedata.bhname}` + "", operation: "New" }
                        setlogdetail(ld);
                        saveLog(ld)
                        setTimeout(() => {
                            window.location.assign("/BanquetHall")
                        }, 1000);
                    })
                    .catch((Response) => {
                        console.log(savedata)
                        toast.error("Name already exists.")

                        document.getElementById("cmdsave").disabled = false
                    })
            } else {
                var ld = { ...savedata, shopid: usershopid, narration: usershopid + document.getElementById("inputname").value.trim() }
                await axios.put(`${hostlink}${api}`, ld)
                    .then((Response) => {
                        toast("Save Sucessfull")
                        var ld = { ...logdetail, newdesc: "Name : " + `${savedata.bhname}` + "", operation: "Edit" }
                        setlogdetail(ld);
                        saveLog(ld)
                        setTimeout(() => {
                            window.location.assign("/BanquetHall")
                        }, 1000);
                    })
                    .catch(() => {
                        toast.error(`${savedata.bhname}` + " Name already exists.")
                        document.getElementById("cmdsave").disabled = false
                    })
            }
        } catch (error) {
        }
    }
    const [savedata, setSavedata] = useState({
        id: "",
        bhname: "",
        fcode: "",
        shopid: usershopid,
        narration: ""
    })

    const { bhname, fcode } = savedata;

    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setSavedata({ ...savedata, [name]: sanitizedValue });
    }

    const saveLog = (ld) => {
        axios.post(`${hostlink}/logbook`, ld)
        console.log(ld)
        console.log("Log Saved...")
    }


    const cmdEdit = (e) => {
        setIsLoading(true);
        setPagemode(1);
        axios.get(`${hostlink}${api}/${e}`)
            .then((Response) => {
                setSavedata(Response.data);
                let y = document.getElementById("dataviewgrid")
                y.style.display = "none"
                document.getElementById("pagemode").innerHTML = " Banquet Hall [ EDIT ]"
                let x = document.getElementById("frm_controll_Add_Edit")
                x.style.display = "block"
                document.getElementById("inputname").focus();
                document.getElementById("cmdnew").disabled = true
                setIsLoading(false);
                var ld = { ...logdetail, olddesc: "Name : " + `${Response.data.bhname}` + "", operation: "Edit" }
                setlogdetail(ld);
            })
            .catch((res) => { console.log("Data Not find") })
    }
    const cmdNew = () => {
        
        setPagemode(0);
        let y = document.getElementById("dataviewgrid")
        y.style.display = "none"

        setlogdetail({ ...logdetail, operation: "New", olddesc: "" });
        document.getElementById("pagemode").innerHTML = " Banquet Hall [ NEW ]"
        let x = document.getElementById("frm_controll_Add_Edit")
        x.style.display = "block"
        document.getElementById("frm_controll_Add_Edit")
        document.getElementById("inputname").focus();
    }
    const deleteData = async (e, name) => {
        try {
            await axios.delete(`${hostlink}${api}/${e}`)
                .then((Response) => {
                    toast.success("Delete Sucessfull")
                    var ld = { ...logdetail, newdesc: "Name : " + deletemsg, operation: "Delete" }
                    setlogdetail(ld);
                    saveLog(ld)
                    setInterval(() => {
                        handleCloseModal();
                        setRefreshData(true)
                    }, 1000);
                })
        } catch (error) { }
    }

    const columns = [
        { name: 'SNo', selector: row => row.id, cell: (row, index) => <div>{index + 1}</div>, "width": "16%" },
        { name: 'Table Name', sortable: true, selector: row => row.bhname, },
        {
            name: 'Actions', "width": "35%", cell: (row) => (<div>
                {(userType === 0 || appState[1]?.[0]?.banquethall?.charAt(2) === '1') ? (<Link to={"/BanquetHall"} onClick={(e) => { cmdEdit(`${row.id}`) }} className='btn btn-sm btn-outline-dark'> <i class="fa-solid fa-pen-to-square"></i> <span className="hide-on-mobile">Edit</span></Link> ) : null}&nbsp;
                {/* <Link onClick={() => deleteData(row.id,row.flname)} className='btn btn-sm btn-danger'>Delete</Link> */}
                {(userType === 0 || appState[1]?.[0]?.banquethall?.charAt(3) === '1') ? (<Link onClick={(e) => { handleOpenModal(row.id, row.bhname) }} className='btn btn-sm btn-danger'> <i class="fa-solid fa-trash"></i> <span className="hide-on-mobile">Delete</span></Link>) : null}&nbsp;
                <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                    <div><p>Are you sure you want to delete this <br /> Banquet Hall : {deletemsg} ?</p><div className="button-container"><button className="yes-button" onClick={(e) => { deleteData(deleteid) }}>Yes</button> <button className="no-button" onClick={handleNoClick}>No</button></div></div>
                </Modal>

            </div>), allowOverflow: true, button: true,
        },
    ];
    useEffect(() => {
        
        axios.get(`${hostlink}/${queryCondition}/bhmas`)
            .then((Response) => {
                console.log("Data Fatched")
                setData(Response.data)
                setRefreshData(false)
            })
            .catch((Response) => {
                console.log("Data Not Fatched")
            })
            axios.get(`${hostlink}/${usershopid}/floor`)
            .then((Response) => {
                console.log("Response.data:", Response.data); // Log the entire response data
        
                if (Array.isArray(Response.data) && Response.data.length > 0) {
                    const firstRecord = Response.data[0];
                    console.log("firstRecord:", firstRecord); // Log the first record
        
                    setSavedata({
                        ...savedata,
                        fcode: firstRecord.id,
                    });
                    setFloor(Response.data);
                } else {
                    console.log("Response.data is not an array or is empty");
                    setSavedata({
                        ...savedata,
                        fcode: null,
                    });
                    setFloor([]);
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
        
    }, [refreshData,usershopid])
    return (
        <div className='p-1'>
            <div id='frm_controll_Add_Edit' className='container shadow rounded' style={{ display: "none" }} ><br />
                <h5 className='font-monospace text-primary'> <i class="fa-solid fa-table-cells-large"></i> <span id='pagemode' > Room [ New ]</span></h5>
                <div className='row p-2 m-1'>
                    <div class="col-md-6">
                        <label className='font-monospace' class="form-label"> Banquet Hall </label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='bhname' value={bhname} class="form-control" id="inputname" />
                    </div>

                    <div class="col-md-6">
                        <label for="inputState" class="form-label">Floor Name</label>
                        <select id="inputfcode" onChange={(e) => { onChangevalue(e) }} name='fcode' value={fcode} class="form-select">
                            {/* <option selected key={0} value={0}>Choose...</option> */}
                            {Floor.map((x) => {
                                return (
                                    <option key={x.id} value={x.id}> {x.flname} </option>
                                )
                            })}
                        </select>
                    </div>
                </div>
                <div class="col-md-12 text-end">
                    <br />
                    <button onClick={(e) => (SaveData(e))} id='cmdsave' class="btn btn-primary">Save</button>  &nbsp;
                    <button onClick={Closeform} type="submit" class="btn btn-primary">Cancel</button>
                    <br />
                    <br />
                </div>
                {/* </form> */}
            </div>
            {/* ----------------------- */}
            <div className='container p-2' id='dataviewgrid'>
                <div className='row p-1'>
                    <div className='col-md-4 p-1'>
                    <h5 className='font-monospace'><i class="fa-solid fa-warehouse"></i> Banquet Hall</h5>
                    </div>
                    <div className='col-md-4 rounded' style={{ display: "flex", alignItems: "center", background: "rgb(245,245,246)" }}>
                        &nbsp; <i class="fa-solid fa-magnifying-glass " ></i> &nbsp;&nbsp; <input type="text" class="" onChange={(e) => { searchData(e) }} name="search" id="search" placeholder="Search" style={{ background: "rgb(245,245,246)", outline: "none", border: "0", width: "100%" }} /> <br />
                    </div>
                    <div className='col-md-4 text-end'>
                    {(userType === 0 || appState[1]?.[0]?.banquethall?.charAt(1) === '1') ? ( <button id='cmdnew' onClick={cmdNew} className='btn btn-outline-primary'> <i class="fa-solid fa-plus"></i> &nbsp; New</button>) : null}
                    </div>
                    <div style={{ height: "80vh" }} className='col-12 '>
                        <table class="table table-striped">
                            <tbody>
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    pagination
                                    fixedHeader
                                    fixedHeaderScrollHeight='530px'
                                />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                    <div><p>Are you sure you want to delete this <br/> Table : {deletemsg} ?</p><div className="button-container"><button className="yes-button" onClick={(e) => { deleteData(deleteid) }}>Yes</button> <button className="no-button" onClick={handleNoClick}>No</button></div></div>
                </Modal>
                {isLoading && <Loading />}
            <ToastContainer autoClose={1000}></ToastContainer>
        </div>
    )
}
export default BanquetHall
