import React, { useRef, useState , useContext} from 'react'
import { useEffect } from 'react'
import hostlink from '../Hostlink/hostlink'
import axios from 'axios'
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Loading from './Loading';
import { UserLoginDetails } from '../Hostlink/UserLoginDetails';

const TransferTableItem = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;
    const [runningtable, setrunningtable] = useState([])
    const [availabletable, setavailabletable] = useState([])
    const roomCodeRef = useRef(null);
    const [items, setItems] = useState([]);
    const [trftabledata, settrftabledata] = useState({ fromtable: 0, totable: 0, nop: 0, wcode: 0, wname: "", totablename: "" });
    const { fromtable, totable, nop, wcode, wname, totablename } = trftabledata;
    const onChangefromtable = (e, f) => {
        const existingItem = runningtable.find(item => item.tname === f);
        if (existingItem) {
            const updatedItDetails = runningtable.map(tabledata => {
                if (tabledata.tname === f) {
                    settrftabledata({ ...trftabledata, fromtable: tabledata.id, nop: tabledata.nop, wcode: tabledata.wcode, wname: tabledata.wname })
                    console.log(tabledata.id)
                }
            });
        }
    }
    const [selectedItems, setSelectedItems] = useState([]);

    const onChangetotable = (e, f) => {
        const existingItem = runningtable.find(item => item.tname === f);
        if (existingItem) {
            const updatedItDetails = runningtable.map(tabledata => {
                if (tabledata.tname === f) {
                    settrftabledata({ ...trftabledata, totable: tabledata.id, totablename: tabledata.tname })
                    console.log(tabledata.id)
                }
            });
        }
    }
    const handlefindItem = (id) => {
        axios.get(`${hostlink}/findtableitem/${usershopid}/${fromtable}`)
            .then(response => {
                setItems(response.data);
            })
            .catch(error => {
                console.error('Error fetching items:', error);
            });
    }
    const conformTRF = async () => {
        
        if (fromtable == 0) {
            toast.error("Please Select From Table..")
            document.getElementById("roomcode").focus();
            return false;
        }
        if (totable == 0) {
            toast.error("Please Select To Table..")
            document.getElementById("roomcode2").focus();
            return false;
        }
        if (totable == fromtable) {
            toast.error("Select Difference Table..")
            document.getElementById("roomcode2").focus();
            return false;
        }
        setIsLoading(true);
        try {
            await axios.get(`${hostlink}/findtableitem/${usershopid}/${totable}`)
                .then((resp) => {
                    if (resp.data.length==0) {
                        setIsLoading(false);
                        toast.error(`Waiter Not Found in ${totablename} table...`);
                       return false;
                    } 
                    const newWname = resp.data[0].wname;
                    const newWcode = resp.data[0].wcode;
                    const newTname = resp.data[0].tablename;
                    const newTcode = resp.data[0].tablecode;

                    const matchingItems = items.filter(item => selectedItems.includes(item.id));

                    // Create a copy of matchingItems and replace the wname property in each object
                    const copiedData = matchingItems.map(item => ({
                        ...item, 
                        wname: newWname,
                        wcode: newWcode,
                        tablename: newTname,
                        tablecode: newTcode, 
                    }));

                       axios.put(`${hostlink}/DineinKOTItemTRF`,copiedData)
            .then((resp) => {  
                setIsLoading(false);
                toast("Save Sucessfull")
                setTimeout(() => {
                window.location.assign("/TransferTableItem")
            }, 1000); }).catch((resp) => { console.log("Something Else") })
                })
           
        } catch {

        }
     
    }
    const handleCancel = () => {
        setSelectedItems([]);
    };
    const handleCheckboxChange = (itemId) => {
        setSelectedItems(prevSelectedItems =>
            prevSelectedItems.includes(itemId)
                ? prevSelectedItems.filter(id => id !== itemId)
                : [...prevSelectedItems, itemId]
        );
    };
    useEffect(() => {
        axios.get(`${hostlink}/${usershopid}/table/1`)
            .then((resp) => { setrunningtable(resp.data); }).catch((resp) => { console.log("Not Fatch") })
    }, [usershopid])
    return (
        <div className='container'>
            <h3 className="text-center my-4"> <i class="fa-solid fa-right-left"></i> Table Item Transfer</h3>
            <div className='row py-1 shadow rounded' style={{ backgroundImage: 'linear-gradient(to right, #1DE6F7, #1DE6F7, #1DE6F7)', color: 'black' }}>

                <div className="col-md-2 text-center align-self-center"><h4 for="inputState" class="form-label"> From Table</h4></div>
                <div class="col-md-2 align-self-center">
                    <select id="roomcode" ref={roomCodeRef} onChange={(e) => { onChangefromtable(e.target.value, e.target.selectedOptions[0].text) }} class="form-select">
                        <option selected key={0} value={0}>Choose...</option>
                        {runningtable.map((x) => {
                            return (
                                <option key={x.tname} value={x.tname} title={x.id}> {x.tname} </option>
                            )
                        })}
                    </select>
                </div>
                <div className="col-md-2 text-start">
                    <button onClick={handlefindItem} className='btn btn-outline-dark'>Get Items</button>
                </div>
                <div className="col-md-2 text-center align-self-center"><h4 for="inputState" class="form-label"> To Table</h4></div>
                <div class="col-md-2 align-self-center">
                    <select id="roomcode2" ref={roomCodeRef} onChange={(e) => { onChangetotable(e.target.value, e.target.selectedOptions[0].text) }} class="form-select">
                        <option selected key={0} value={0}>Choose...</option>
                        {runningtable.map((x) => {
                            return (
                                <option key={x.tname} value={x.tname} title={x.id}> {x.tname} </option>
                            )
                        })}
                    </select>
                </div>
            </div>
            {/* <div className="form-check">
                <input
                    type="checkbox"
                    className="form-check-input"
                    id="selectAll"
                    checked={selectAll}
                    onChange={handleSelectAll}
                />
                <label className="form-check-label" htmlFor="selectAll">
                    Select All
                </label>
            </div> */}
            <br />

            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>Item Name</th>
                        <th>Qty</th>
                        <th>Table</th>
                        <th>Waiter</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map(item => (
                        <tr key={item.id}>
                            <td>
                                <input
                                    type="checkbox"
                                    className="form-check-input me-2"
                                    checked={selectedItems.includes(item.id)}
                                    onChange={() => handleCheckboxChange(item.id)}
                                />
                                {item.itname}
                            </td>
                            <td>{item.qty}</td>
                            <td>{item.tablename}</td>
                            <td>{item.wname}</td>
                        </tr>
                    ))}
                </tbody>
            </table>





            <div className="d-flex justify-content-end">
                <button className="btn btn-success me-2" onClick={conformTRF}>
                    <i class="fa-solid fa-right-left"></i> Confirm Transfer
                </button>
                <Link to={"/"} className="btn btn-secondary" onClick={handleCancel}>
                    Cancel
                </Link>
            </div>
            {/* <div className="text-center my-4">
                <button onClick={() => { conformTRF() }} className="btn btn-success" ><i class="fa-solid fa-right-left"></i> Confirm Transfer</button>
            </div> */}

            <ToastContainer autoClose={1000}></ToastContainer>
            {isLoading && <Loading />}
        </div>
    )
}

export default TransferTableItem