import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ToolPass = () => {
    const [toolpass, settoolpass] = useState("")
    const loginpasschange =(e)=>{
        settoolpass(e.target.value)
        
     }
    const login =()=>{
       if (toolpass ==="mpka140")
       {
        window.location.assign("/SettingsTool")
       }else{
        toast.error("Password Invalid...")
        document.getElementById("toolpass").focus();
       }

    }
    useEffect(()=>{
        document.getElementById("toolpass").focus();
    },[])
  return (
    <div>
        <div className='container'>
            <br /><br />
            <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-1"><label htmlFor="">Password : </label></div>
                <div className="col-md-3"><input type="password"  name='toolpass' onChange={(e)=>{loginpasschange(e)}} id='toolpass' value={toolpass} className='form-control' /></div>
                <div className="col-md-3"><button onClick={()=>{login()}} className='btn btn-dark'>Submit</button></div>
            </div>
        </div>
        <ToastContainer autoClose={1000}></ToastContainer>
    </div>
  )
}

export default ToolPass