import React, { useEffect, useRef, useState, useContext } from 'react';
import axios from 'axios';
import hostlink from '../Hostlink/hostlink';
import * as XLSX from 'xlsx';
import { PDFDownloadLink, PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { UserLoginDetails } from '../Hostlink/UserLoginDetails';

const Rpt_StockTRf = () => {
    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;
    const usershopdeptcode = appState?.[0]?.deptcode || 0;
    const usershopidref = appState?.[0]?.hotelshopid || 0;
    const [RoomType, setRoomType] = useState([]);
    const [Room, setRoom] = useState([]);
    const [deptdata, setdeptdata] = useState([])
    const [data, setData] = useState([]);
    const today = new Date();
    const [frmdata, setfrmdata] = useState({
        fromdate: today.toISOString().split('T')[0],
        todate: today.toISOString().split('T')[0],
        deptcode: 0,
        status: 0,
        pono:""
    });
    const [totalSums, setTotalSums] = useState({});

    const { fromdate, todate, status, deptcode,pono } = frmdata;
    const tableRef = useRef(null);
    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setfrmdata({ ...frmdata, [name]: sanitizedValue });
    };
    
    const queryCondition = usershopidref == 0
        ? `${usershopid}`
        : `${usershopidref}`;
    const queryConditionProductionDept = usershopidref !== 0 ? `/ProductionreportDept/${queryCondition}` : "ProductionreportDept";
    const queryConditionProductionDeptAll = usershopidref !== 0 ? `/Productionreport/${queryCondition}` : "Productionreport";
    const loaddata = async (e) => {
        try {
            const searchpo = pono === "" ? "-1" : pono;
                console.log(`${hostlink}/stktrfReportStorePOSearch/${queryCondition}/${usershopdeptcode}/${deptcode}/${fromdate}/${todate}/0/${searchpo}`)
                const billResponse = await axios.get(`${hostlink}/stktrfReportStorePOSearch/${queryCondition}/${usershopdeptcode}/${deptcode}/${fromdate}/${todate}/0/${searchpo}`);
                const billData = billResponse.data;
                setData(billResponse.data)
            
        } catch (error) {
            console.log("Data Not Found...");
        }
    };
    useEffect(() => {
        axios.get(`${hostlink}/${queryCondition}/deptmas`)
            .then((Response) => { setdeptdata(Response.data) })
    }, [usershopid])

    const exportToExcel = () => {
        const ws = XLSX.utils.table_to_sheet(tableRef.current);
        const boldAndColorStyle = {
            font: { bold: true },
            fill: { fgColor: { rgb: "FFFF00" } }
        };
        const headerRow = XLSX.utils.decode_range(ws['!ref']).e.c;
        for (let c = 0; c <= headerRow; c++) {
            const cellAddress = XLSX.utils.encode_cell({ r: 0, c });
            if (ws[cellAddress].v === 'Sr No') {
                ws[cellAddress].s = boldAndColorStyle;
                for (let r = 1; r <= XLSX.utils.decode_range(ws['!ref']).e.r; r++) {
                    const dataCellAddress = XLSX.utils.encode_cell({ r, c });
                    if (ws[dataCellAddress]) {
                        ws[dataCellAddress].s = boldAndColorStyle;
                    }
                }
                break;
            }
        }
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        XLSX.writeFile(wb, 'Stock Transfer.xlsx');
    };

    return (
        <div className='m-1'>
            <div className='container p-3 shadow rounded' style={{ transition: 'box-shadow 0.3s' }}>
                <div className='row'>
                    <div className='col-md-3 align-self-center'>
                        <h5 className='font-monospace text-primary'> <i class="fa-solid fa-right-left"></i> <span id='pagemode'> Stock Transfer Report</span></h5>
                    </div>
                    <div className='col-6 col-md-1 align-self-center text-end'>
                        <label htmlFor="fromdate" className="form-label">From Date</label>
                    </div>
                    <div className='col-6 col-md-2'>
                        <input type="date" id="fromdate" name='fromdate' onChange={(e) => { onChangevalue(e) }} value={fromdate} className="form-control" />
                    </div>
                    <div className='col-6 col-6 col-md-1 align-self-center text-end'>
                        <label htmlFor="todate" className="form-label">To Date</label>
                    </div>
                    <div className='col-6 col-md-2'>
                        <input type="date" id="todate" name='todate' onChange={(e) => { onChangevalue(e) }} value={todate} className="form-control" />
                    </div>
                    <div className='col-6 col-md-1 align-self-center text-end'>
                        <label htmlFor="roomtype" className="form-label">Department</label>
                    </div>
                    <div className="col-6 col-md-2 text-start-0 align-self-center">
                        <select id="deptcode" name='deptcode' value={deptcode} onChange={(e) => { onChangevalue(e) }} class="form-select">
                            <option selected key={0} value={0}>All</option>
                            {deptdata.map((x) => {
                                return (
                                    <option key={x.id} value={x.id}> {x.deptname} </option>
                                )
                            })}
                        </select>
                    </div>
                    <div className='col-6 col-md-4 align-self-center text-end mt-2'>
                    <label htmlFor="roomtype" className="form-label">PO No </label>
                    </div>

                    <div className='col-6 col-md-2 align-self-center text-end mt-2'>
                        <input type="text" id="pono" name='pono' onChange={(e) => { onChangevalue(e) }} value={pono} className="form-control" placeholder='Search PO No..' />
                    </div>

                    <div className='col-md-6 align-self-center text-end mt-2'>
                        {/* <button onClick={() => { loaddata(0); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-magnifying-glass-chart"></i> Department-wise </button> */}
                        <button onClick={() => { loaddata(1); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-magnifying-glass-chart"></i> Show </button>
                        <button onClick={() => { exportToExcel(); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-table"></i> Excel</button>
                        {/* <PDFDownloadLink document={<PDFDocument data={data} fromdate={fromdate} todate={todate} />} fileName="Stock Transfer Report.pdf">
                            {({ blob, url, loading, error }) =>
                                loading ? 'Loading' : <button className='btn btn-sm btn-outline-primary' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-regular fa-file-pdf"></i> PDF</button>
                            }
                        </PDFDownloadLink> */}
                    </div>
                </div>
            </div>


            <br />
            {/* maxHeight: "400px", */}
            <div className='container-fluid' style={{ overflow: "auto" }}>
                <table className="table table-hover table-striped " ref={tableRef}>

                    <thead className='text-center' >
                        <tr >
                            <th className='text-start' scope="col">Date</th>
                            <th className='text-start' scope="col">from</th>
                            <th className='text-start' scope="col">To</th>
                            <th className='text-start' scope="col">Po No</th>
                            <th className='text-start' scope="col">Item Name</th>
                            <th className='text-end' scope="col">Qty</th>
                            <th className='text-start' scope="col">Unit</th>
                            <th className='text-end' scope="col">Rate</th>
                            <th className='text-end' scope="col">Amount</th>
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        {data.map((res, x) => {
                            // Compare the current value with the previous one
                            const isDuplicate = x > 0 && res[0].shopvno === data[x - 1][0].shopvno;
                            return (
                                <tr key={x}>
                                    <td className='text-start'>{isDuplicate ? '' : res[0].vdate ? new Date(res[0].vdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</td>
                                    <td className='text-start'>{isDuplicate ? '' : res[1]}</td>
                                    <td className='text-start'>{isDuplicate ? '' : res[2]}</td>
                                    <td className='text-start'>{isDuplicate ? '' : res[0].pono}</td>
                                    <td className='text-start'>{res[0].rawname}</td>
                                    <td className='text-end'>{res[0].qty}</td>
                                    <td className='text-start'>{res[3]}</td>
                                    <td className='text-end'>{res[0].rate}</td>
                                    <td className='text-end'>{Number(res[0].rate*res[0].qty).toFixed(2)}</td>
                                </tr>
                            );
                        })}

                        <tr class="table-info">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className='text-end'><h6>Total :</h6></td>
                            <td className='text-end'><h6>{Number(data.reduce((total, res) => total + parseFloat(Number(res[0].qty)), 0)).toFixed(2)}</h6></td>
                            <td></td>
                            <td></td>
                            <td className='text-end'><h6>{Number(data.reduce((total, res) => total + parseFloat(Number(res[0].qty*res[0].rate)), 0)).toFixed(2)}</h6></td>
                            
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}
// const PDFDocument = ({ data, fromdate, todate }) => {
//     // Calculate total bill amount
//     // const totalBillAmount = data.reduce((total, res) => total + parseFloat(res.SaleMasDTO.totblamt), 0);
//     return (
//         <Document>
//             <Page orientation="landscape">
//                 <View style={styles.container}>
//                     <Text style={styles.title}>Stock Transfer Report</Text>
//                     <Text style={styles.title}>From : {fromdate} To : {todate}</Text>
//                     <View style={styles.table}>
//                         <View style={styles.tableRow}>
//                             <Text style={styles.headerCell}>Date</Text>
//                             <Text style={styles.headerCell}>From</Text>
//                             <Text style={styles.headerCell}>To</Text>
//                             <Text style={styles.headerCell}>Po No</Text>
//                             <Text style={styles.headerCell}>Item Name</Text>
//                             <Text style={styles.headerCell}>Qty</Text>
//                             <Text style={styles.headerCell}>Unit</Text>
                            
//                         </View>
//                         {data.map((res, x) => {
//                             // Compare the current value with the previous one
//                             const isDuplicate = x > 0 && res[0].shopvno === data[x - 1][0].shopvno;
//                             return (
//                                 <View key={x} style={styles.tableRow}>
//                                 <Text style={styles.cell}>{isDuplicate ? '' : res[0].vdate ? new Date(res[0].vdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</Text>
//                                 <Text style={styles.cell}>{isDuplicate ? '' : res[1]}</Text>
//                                 <Text style={styles.cell}>{isDuplicate ? '' : res[2]}</Text>
//                                 <Text style={styles.cell}>{isDuplicate ? '' : res[0].pono}</Text>
//                                 <Text style={styles.cell}>{res[0].rawname}</Text>
//                                 <Text style={styles.cell}>{res[0].qty}</Text>
//                                 <Text style={styles.cell}>{res[3]}</Text>
//                             </View>
//                             );
//                         })}
//                         <View style={styles.tableRow}>
//                             <Text style={styles.cell}></Text>
//                             <Text style={styles.cell}></Text>
//                             <Text style={styles.cell}></Text>
//                             <Text style={styles.cell}></Text>
//                             <Text style={styles.cell}>Total :</Text>
//                             <Text style={styles.cell}>{data.reduce((total, res) => total + parseFloat(Number(res[0].qty)), 0)}</Text>
//                             <Text style={styles.cell}></Text>
//                         </View>
//                     </View>
//                 </View>
//             </Page>
//         </Document>
//     );
// };

const styles = StyleSheet.create({
    container: {
        padding: 20,
    },
    title: {
        fontSize: 16,
        fontStyle: 'Times New Roman',
        marginBottom: 20,
        textAlign: 'center',
    },
    table: {
        display: 'table',
        width: '100%',
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderColor: '#000',
    },
    headerCell: {
        fontSize: 8,
        fontWeight: 'bold',
        padding: 5,
        flex: 1,
        fontStyle: 'Times New Roman',
        textAlign: 'center',
    },
    cell: {
        fontSize: 8,
        padding: 5,
        flex: 1,
        fontStyle: 'Times New Roman',
        textAlign: 'center',
    },
});

export default Rpt_StockTRf